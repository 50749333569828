import { Outlet } from 'react-router-dom';
import HeaderToolbar from 'views/HeaderToolbar';
import { DefaultFooter } from 'views/common';
import { Layout, HeaderLayout, ContentLayout, FooterLayout } from '../common';
import useUserPage from './useUserPage';
import UserPageLogo from './UserPageLogo';

const UserPageLayout = () => {
    const { initializing, initialized } = useUserPage();
    return (
        <Layout>
            <HeaderLayout logo={<UserPageLogo />} loading={initializing}>
                {initialized && <HeaderToolbar />}
            </HeaderLayout>
            <ContentLayout>{initialized ? <Outlet /> : null}</ContentLayout>
            <FooterLayout>
                <DefaultFooter />
            </FooterLayout>
        </Layout>
    );
};

export default UserPageLayout;
