import { find } from 'lodash';
import { useMemo } from 'react';
import useCurrentClientId from './useCurrentClientId';
import { useOverviewQuery } from 'views/common';

const useCurrentClient = () => {
    const clientId = useCurrentClientId();
    const { data } = useOverviewQuery();
    const clients = data?.data.clients;

    return useMemo(() => {
        return find(clients, { id: clientId });
    }, [clients, clientId]);
};

export default useCurrentClient;
