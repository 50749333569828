import { Stack, Typography } from '@mui/material';
import { Empty as EmptyIcon } from 'components/icons';

type EmptyProps = {
    description?: string;
};

const Empty: React.FC<EmptyProps> = ({ description = 'нет данных' }) => {
    return (
        <Stack alignItems="center" justifyContent="center" height="150px">
            <Stack alignItems="center">
                <EmptyIcon sx={{ fontSize: '80px' }} />
                <Typography variant="caption" color="textSecondary">
                    {description}
                </Typography>
            </Stack>
        </Stack>
    );
};

export default Empty;
