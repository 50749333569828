import theme from 'theme';
import { ContractStatusType } from 'types';

export enum CurrencyFutureStatus {
    NEW = 'NEW',
    ACTIVE = 'ACTIVE',
    CLOSED = 'CLOSED',
    UNREGISTERED = 'UNREGISTERED',
}

const { NEW, ACTIVE, CLOSED, UNREGISTERED } = CurrencyFutureStatus;
const { palette } = theme;

const contractStatuses: { [key: string]: ContractStatusType } = {
    [NEW]: {
        code: NEW,
        title: 'новый',
        color: palette.info.light,
    },
    [ACTIVE]: {
        code: ACTIVE,
        title: 'рабочий',
        color: palette.primary.light,
    },
    [CLOSED]: {
        code: CLOSED,
        title: 'закрыт',
        color: palette.grey[500],
    },
    [UNREGISTERED]: {
        code: UNREGISTERED,
        title: 'снят с учета',
        color: palette.error.main,
    },
    UNDEFINED: {
        code: '???',
        title: 'неизвестный статус',
        color: palette.grey[800],
    },
};

export default contractStatuses;
