import { Outlet } from 'react-router-dom';
import { Logo } from 'components';
import { Layout, HeaderLayout, ContentLayout, FooterLayout } from './common';
import { DefaultFooter } from 'views/common';
import { BANK_URL } from 'configs/urls';

const PreorderLayout = () => (
    <Layout>
        <HeaderLayout
            logo={<Logo logo="bank-logo.svg?1" rootPath={BANK_URL} />}
        />
        <ContentLayout>
            <Outlet />
        </ContentLayout>
        <FooterLayout>
            <DefaultFooter />
        </FooterLayout>
    </Layout>
);

export default PreorderLayout;
