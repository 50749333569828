import { Branch } from 'enums';
import DocumentPacket from './DocumentPacket';
import Manager from './Manager';
import Products from './Products';
import PaymentOffer from './PaymentOffer';

export enum OfferType {
    ACCOUNT_OPENING_RKO = 'ACCOUNT_OPENING_RKO',
}

export enum OfferState {
    DRAFT = 'DRAFT',
    PUBLISHED = 'PUBLISHED',
    SENT = 'SENT',
    REJECTED = 'REJECTED',
    CONFIRMED = 'CONFIRMED',
    APPOINTED = 'APPOINTED',
    CANCELED = 'CANCELED',
    EXPIRED = 'EXPIRED',
    DECLINED = 'DECLINED',
}

export enum QuestionaryState {
    NEW = 'NEW',
    VERIFICATION = 'VERIFICATION',
    APPROVED = 'APPROVED',
}

export type OfferContent = {
    en: string;
    de: string;
    ru: string;
    cn: string;
};

export type OfferOptions = {
    corpCardPrice: number;
    customsCardPrice: number;
    openingPhysicalAccountPrice: number;
    physicalCardPrice: number;
    openingAccountCurrenciesPrice: Record<string, number>;
};

export type OfferOrderService = {
    code: string;
    name: string;
    price: number;
    count: number;
    deletable: boolean;
    subServices?: OfferOrderService[];
};

export type OfferOrder = {
    services: OfferOrderService[];
    total: number;
};

export type OfferPreview = {
    accessId: string;
    type: OfferType;
    state: OfferState;
    publishedAt?: string;
};

export type OfficePreorder = {
    officeId: number | null;
    employeeId?: number | null;
};

type Offer = {
    id: number;
    accessId: string;
    type: OfferType;
    state: OfferState;
    questionaryState: QuestionaryState;
    allowOutsideAppointment: boolean;
    outsideAppointRequested: boolean;
    officePreorder: OfficePreorder;
    bankAccount?: string;
    branch: Branch;
    content: OfferContent;
    documentPacket: DocumentPacket | null;
    feedbackEmail?: string;
    hasClient: boolean;
    makeAct: boolean;
    manager: Manager;
    options: OfferOptions;
    products: Products;
    order: OfferOrder | null;
    publishedAt?: string;
    recommendation: boolean;
    subjectTaxId: string;
    subjectName: string;
    recipient?: string | null;
    recipients?: string[];
    payment?: PaymentOffer;
};

export default Offer;
