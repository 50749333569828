import ComponentsTheme from './ComponentsTheme';

const MuiStepIcon: ComponentsTheme['MuiStepIcon'] = {
    styleOverrides: {
        text: {
            fill: '#ffffff',
        },
    },
};

export default MuiStepIcon;
