import DocumentFile from './DocumentFile';

export enum DocumentPacketState {
    NEW = 'NEW',
    VERIFICATION = 'VERIFICATION',
    REFUSED = 'REFUSED',
    APPROVED = 'APPROVED',
    ERROR_IN_DOCS = 'ERROR_IN_DOCS',
    NEED_MORE_DOCS = 'NEED_MORE_DOCS',
}

export enum DocumentState {
    NEW = 'NEW',
    APPROVED = 'APPROVED',
    REFUSED = 'REFUSED',
}

export enum DocumentType {
    // GENERAL TYPES

    // legal documents
    CREATE_LEGAL_PROTOCOL = 'CREATE_LEGAL_PROTOCOL',
    CHARTER = 'CHARTER',
    LEADER_ELECTION_PROTOCOL_AND_POWERS = 'LEADER_ELECTION_PROTOCOL_AND_POWERS',
    PASSPORTS = 'PASSPORTS',

    // individual documents
    PASSPORT = 'PASSPORT',
    TAX_DECLARATION = 'TAX_DECLARATION',

    // common
    RECOMMENDATION = 'RECOMMENDATION',

    // OTHER TYPES

    // free documents
    FREE = 'FREE',
    ARCHIVE = 'ARCHIVE',

    // additional
    QUESTIONARY = 'QUESTIONARY',
    PAYMENT_DOCUMENT = 'PAYMENT_DOCUMENT',
}

export type DocumentData = Record<any, any> & {
    type: DocumentType;
    history?: any[];
};

export type DocumentDetailedState = {
    value: DocumentState;
    comment: string | null;
};

export type Document = {
    id: number;
    files: DocumentFile[];
    data: DocumentData;
    state: DocumentDetailedState;
};

type DocumentPacket = {
    id: number;
    state: DocumentPacketState;
    documents: Document[];
    comment: string | null;
};

export default DocumentPacket;
