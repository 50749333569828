import { Box, BoxProps, Container, Typography } from '@mui/material';
import noPrint from 'theme/noPrint';

const VERSION = process.env.REACT_APP_VERSION;

const FooterLayout: React.FC<BoxProps> = ({ children, ...rest }) => (
    <Box
        component="footer"
        flexShrink={0}
        minHeight="260px"
        display="flex"
        alignItems="flex-end"
        pb="23px"
        position="relative"
        sx={{
            backgroundImage: 'url(/images/footer-background.jpeg)',
            backgroundRepeat: 'repeat-x',
            backgroundPositionX: 'center',
            backgroundPositionY: 'top',
            backgroundSize: '1180px',
            ...noPrint,
        }}
        {...rest}
    >
        <Container maxWidth="lg">{children}</Container>
        <Typography
            variant="caption"
            color="#ddd"
            position="absolute"
            bottom="5px"
            right="10px"
        >
            {VERSION}
        </Typography>
    </Box>
);

export default FooterLayout;
