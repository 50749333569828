import { HumanName } from 'react-query-client/dto';

const getFullName = (data?: HumanName): string => {
    if (!data) return '';
    return `${data.lastName} ${data.firstName} ${
        data.middleName ? data.middleName : ''
    }`;
};

export default getFullName;
