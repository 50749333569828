import { Currency } from 'types';

const currencies: { [key: string]: Currency } = {
    RUB: {
        code: 'RUB',
        num: 643,
        symbol: '₽',
        color: '#096dd9',
        name: 'Российский рубль',
    },
    USD: {
        code: 'USD',
        num: 840,
        symbol: '$',
        color: '#389e0d',
        name: 'Доллар США',
    },
    EUR: { code: 'EUR', num: 978, symbol: '€', color: '#d48806', name: 'Евро' },
    RUR: {
        code: 'RUR',
        num: 810,
        symbol: '₽',
        color: '#096dd9',
        name: 'Российский рубль',
    },
    CNY: { code: 'CNY', num: 156, symbol: '¥', color: '#531dab', name: 'Юань' },
    GBP: {
        code: 'GBP',
        num: 826,
        symbol: '£',
        color: '#4a91ff',
        name: 'Фунт стерлингов',
    },
    SEK: {
        code: 'SEK',
        num: 752,
        symbol: 'kr',
        color: '#777777',
        name: 'Шведская крона',
    },
    INR: {
        code: 'INR',
        num: 356,
        symbol: '₨',
        color: '#777777',
        name: 'Индийская рупия',
    },
    BYN: {
        code: 'BYN',
        num: 933,
        symbol: 'р.',
        color: '#777777',
        name: 'Белорусский рубль',
    },
    BYR: {
        code: 'BYR',
        num: 974,
        symbol: 'р.',
        color: '#777777',
        name: 'Белорусский рубль',
    },
    UAH: {
        code: 'UAH',
        num: 980,
        symbol: '₴',
        color: '#777777',
        name: 'Гривна',
    },
    KZT: {
        code: 'KZT',
        num: 398,
        symbol: '₸',
        color: '#777777',
        name: 'Тенге',
    },
    CAD: {
        code: 'CAD',
        num: 124,
        symbol: '$',
        color: '#777777',
        name: 'Канадский доллар',
    },
    CRC: {
        code: 'CRC',
        num: 43353,
        symbol: '₡',
        color: '#777777',
        name: 'Колон',
    },

    AZN: {
        code: 'AZN',
        num: 944,
        symbol: '₼',
        color: '#777777',
        name: 'Азербайджанский манат',
    },
    TRY: {
        code: 'TRY',
        num: 949,
        symbol: '₺',
        color: '#777777',
        name: 'Турецкая лира',
    },
    AED: {
        code: 'AED',
        num: 784,
        symbol: 'DH',
        color: '#777777',
        name: 'Дирхам ОАЭ',
    },
    AMD: {
        code: 'AMD',
        num: 51,
        symbol: '֏',
        color: '#777777',
        name: 'Армянский драм',
    },
    UZS: {
        code: 'UZS',
        num: 860,
        symbol: 'So`m',
        color: '#777777',
        name: 'Узбекский сум',
    },
    KGS: {
        code: 'KGS',
        num: 417,
        symbol: 'с',
        color: '#777777',
        name: 'Киргизский сом',
    },
    GEL: {
        code: 'GEL',
        num: 981,
        symbol: '₾',
        color: '#777777',
        name: 'Грузинский Лари',
    },
    TJS: {
        code: 'TJS',
        num: 972,
        symbol: 'сом',
        color: '#777777',
        name: 'Таджикский сомони',
    },
    RSD: {
        code: 'RSD',
        num: 941,
        symbol: 'din',
        color: '#777777',
        name: 'Сербский динар',
    },
    UNDEFINED: {
        code: '???',
        num: NaN,
        symbol: '?',
        color: '#777777',
        name: 'Не определена',
    },
};

export default currencies;
