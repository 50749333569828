import { StackProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

const StyledLogo = styled(RouterLink)(({ theme }) => ({
    width: '160px',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPositionY: 'center',
    borderBottom: 'none',
    transition: '300ms ease-out transform',
    '&:hover': {
        transform: 'scale(1.05)',
    },
    [theme.breakpoints.down('sm')]: {
        width: '120px',
    },
}));

type LogoProps = StackProps & {
    logo: string;
    rootPath?: string;
};

const Logo: React.FC<LogoProps> = ({ logo, rootPath = '/' }) => {
    return (
        <StyledLogo
            to={rootPath}
            aria-label="Перейти на главную"
            sx={{
                backgroundImage: `url('/images/${logo}')`,
            }}
        />
    );
};

export default Logo;
