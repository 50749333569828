import { useScrollUp } from 'components/hooks';
import { useRegChangePage } from 'metrika';
import AppRoutes from './AppRoutes';

const Main = () => {
    useScrollUp();
    useRegChangePage();

    return <AppRoutes />;
};

export default Main;
