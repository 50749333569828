/**
 * Check file size.
 * @param maxSize Max file size (Mb)
 * @param size The number of bytes of target file.
 * @returns true | false
 */
const isValidFileSize = (maxSize = 50, size: number): boolean => {
    const BYTES = 1024;
    return size > 0 && size <= maxSize * BYTES * BYTES;
};

export default isValidFileSize;
