import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import { map } from 'lodash';
import { Fragment, useCallback, useMemo, useState } from 'react';
import Cell from './Cell';
import TableColumn from './TableColumn';
import TableProps from './TableProps';
import getColumnsCount from './getColumnsCount';

type RowProps<TData> = Pick<
    TableProps<TData>,
    'columns' | 'hoverable' | 'expandable' | 'onRowClick' | 'rowActions'
> & {
    record: TData;
};

const Row = function <TData>(props: RowProps<TData>) {
    const { columns, record, hoverable, expandable, rowActions, onRowClick } =
        props;
    const [expanded, setExpanded] = useState(false);
    const columnsCount = getColumnsCount(props);

    const handleExpand = useCallback(() => {
        setExpanded((prevValue) => !prevValue);
    }, []);

    const cells = useMemo(() => {
        return map(columns, (column: TableColumn<TData>, index) => {
            return (
                <Cell<TData>
                    key={index}
                    column={column}
                    record={record}
                    onClick={onRowClick}
                    onExpand={handleExpand}
                />
            );
        });
    }, [columns, record, handleExpand, onRowClick]);

    const hover = hoverable && !expanded;
    const rowSx = expandable
        ? { '& > td': { borderBottom: 'unset' } }
        : undefined;

    return (
        <Fragment>
            <TableRow hover={hover} sx={rowSx}>
                {expandable && (
                    <TableCell>
                        <IconButton size="small" onClick={handleExpand}>
                            {expanded ? (
                                <KeyboardArrowUp />
                            ) : (
                                <KeyboardArrowDown />
                            )}
                        </IconButton>
                    </TableCell>
                )}
                {cells}
                {rowActions && (
                    <TableCell align="right">{rowActions(record)}</TableCell>
                )}
            </TableRow>
            {expandable && (
                <TableRow>
                    <TableCell
                        sx={{
                            paddingBottom: 0,
                            paddingTop: 0,
                        }}
                        colSpan={1}
                    />
                    <TableCell
                        sx={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                        }}
                        colSpan={columnsCount - 1}
                    >
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <Box sx={{ paddingBottom: '20px' }}>
                                {expandable.expandedRowRender(record)}
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </Fragment>
    );
};

export default Row;
