import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import type {} from '@mui/lab/themeAugmentation';
import palette from './palette';
import typography from './typography';
import zIndex from './zIndex';
import breakpoints from './breakpoints';
import MuiCssBaseline from './MuiCssBaseline';
import MuiAppBar from './MuiAppBar';
import MuiLink from './MuiLink';
import MuiStepIcon from './MuiStepIcon';
import MuiButton from './MuiButton';
import MuiModal from './MuiModal';
import MuiTooltip from './MuiTooltip';
import MuiTabPanel from './MuiTabPanel';
import MuiMenu from './MuiMenu';
import MuiTextField from './MuiTextField';
import MuiAlert from './MuiAlert';
import MuiToggleButton from './MuiToggleButton';
import MuiContainer from './MuiContainer';

let theme = createTheme({
    palette,
    typography,
    zIndex,
    breakpoints,
    components: {
        MuiCssBaseline,
        MuiAppBar,
        MuiLink,
        MuiStepIcon,
        MuiButton,
        MuiModal,
        MuiTooltip,
        MuiTabPanel,
        MuiMenu,
        MuiTextField,
        MuiAlert,
        MuiToggleButton,
        MuiContainer,
    },
});

theme = responsiveFontSizes(theme);

export default theme;
