import { useShow } from 'components/hooks';
import React, { Fragment, MouseEvent, ReactElement } from 'react';
import { ReactNode } from 'react';
import AlertModal, { AlertModalProps } from './AlertModal';

type ShowAlertProps = Omit<AlertModalProps, 'open' | 'onClose'> & {
    children: ReactNode;
    content: ReactNode;
};

const ShowAlert: React.FC<ShowAlertProps> = ({
    children,
    content,
    ...alertModalProps
}) => {
    const [open, onOpen, onClose] = useShow();

    const handleTriggerClick = (event: MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        onOpen();
    };

    const target = children as ReactElement<any>;
    const trigger = React.cloneElement(target, {
        ...target.props,
        onClick: handleTriggerClick,
    });

    return (
        <Fragment>
            <AlertModal {...alertModalProps} open={open} onClose={onClose}>
                {content}
            </AlertModal>
            {trigger}
        </Fragment>
    );
};

export default ShowAlert;
