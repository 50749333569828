import { Keys } from 'enums';
import { find } from 'lodash';
import { useCallback } from 'react';
import { Client } from 'react-query-client/dto';
import { useNavigate } from 'react-router-dom';
import { Data } from 'types';

const { SELECTED_CLIENT_ID_KEY } = Keys;

const useMultiple = (props: Data<Client[]>) => {
    const { data } = props;
    const navigate = useNavigate();
    // const { pathname } = useLocation();

    const onSelect = useCallback(
        (clientId: string) => {
            const client = find(data, { id: clientId });
            // const parsedPath = /^\/clients\/[\w-]+\/([\w-]+)\/*/gi.exec(
            //     pathname
            // );
            // const clientPage = parsedPath?.[1];
            // const newPath = `clients/${clientId}${
            //     clientPage ? `/${clientPage}` : ''
            // }`;

            localStorage.setItem(SELECTED_CLIENT_ID_KEY, clientId);

            // navigate(newPath);
            navigate(`/clients/${client?.id}`);
        },
        [data, navigate]
    );

    return {
        onSelect,
    };
};

export default useMultiple;
