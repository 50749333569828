import {
    UseQueryOptions,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { chain, filter, first, map, some } from 'lodash';
import produce from 'immer';
import { useCallback } from 'react';
import { api } from 'react-query-client';
import { Client, Overview } from 'react-query-client/dto';
import { useRecoilValue } from 'recoil';
import { loggedInSelector } from 'state/loggedIn';
import { ApiResponse } from 'types';
import { OfferState } from 'types/Offer';
import { AccountState, AccountType } from 'react-query-client/dto/Account';

export type OverviewResponse = ApiResponse<Overview>;

export const overviewQueryKey = ['overview'];

export const fetchOverview = async (options?: AxiosRequestConfig) =>
    (await api.get('/overview', options)).data;

export const useFetchOverview = () => {
    const clientQuery = useQueryClient();
    return useCallback(async () => {
        return await clientQuery.fetchQuery<OverviewResponse, Error>({
            queryKey: overviewQueryKey,
            queryFn: () => fetchOverview(),
        });
    }, [clientQuery]);
};

export const hasOpenedAccount = (client?: Client) =>
    some(
        client?.accounts,
        ({ type, state, num }) =>
            (type === AccountType.RKO || type === AccountType.DEFAULT) &&
            state === AccountState.ACTIVE
    );

const getPrimaryClientId = (clients: Client[]) => {
    const clientsWithOffers = chain(clients)
        .flatMap((client) => {
            return map(client.proposals, (proposal) => ({
                id: client.id,
                proposal,
            }));
        })
        .orderBy(['proposal.createdAt'], ['desc'])
        .value();

    return first(clientsWithOffers)?.id || first(clients)?.id;
};

const select = (data: OverviewResponse) => {
    return produce(data, (draft) => {
        const clients = data.data.clients;
        draft.data.primaryClientId = getPrimaryClientId(clients);
        draft.data.clients = map(clients, (client) => ({
            ...client,
            proposals: filter(
                client.proposals,
                ({ state }) =>
                    state === OfferState.CONFIRMED ||
                    state === OfferState.DECLINED
            ),
            hasOpenedAccount: hasOpenedAccount(client),
        }));
    });
};

const useOverviewQuery = (
    options?: UseQueryOptions<OverviewResponse, any, OverviewResponse>
) => {
    const loggedIn = useRecoilValue(loggedInSelector);
    return useQuery<OverviewResponse, Error>({
        queryKey: overviewQueryKey,
        queryFn: fetchOverview,
        useErrorBoundary: true,
        enabled: loggedIn,
        retry: 10,
        select,
        ...options,
    });
};

export default useOverviewQuery;
