import { Stack, StackProps } from '@mui/material';

const FilterFields: React.FC<StackProps> = (props) => {
    return (
        <Stack
            direction="row"
            width="100%"
            gap={2}
            flexWrap="nowrap"
            {...props}
        />
    );
};

export default FilterFields;
