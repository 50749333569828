import { Paper, PaperProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const Panel = styled((props: PaperProps) => <Paper elevation={3} {...props} />)(
    {
        padding: '32px',
    }
);

export default Panel;
