import { Divider, Typography, Stack } from '@mui/material';
import {
    ErrorAlert,
    Page,
    PageContent,
    PageHeader,
    PageTitle,
} from 'components';
import Content from './Content';
import {
    DeclinedOffer,
    Manager,
    RenderManager,
    useOfferQuery,
} from 'views/common';
import { isStatus } from 'helpers/api';
import NoMatch from 'views/NoMatch';
import Expired from './Expired';
import { OfferState } from 'types/Offer';
import AgreeButton from './AgreeButton';

const Offer = () => {
    const { data: offer, isLoading, isError, error } = useOfferQuery();

    if (isStatus(error, 410)) {
        return <Expired data={error?.response?.data?.data} />;
    }

    if (isStatus(error, 404)) {
        return <NoMatch />;
    }

    if (offer?.data.state === OfferState.DECLINED) {
        return <DeclinedOffer />;
    }

    if (isError) {
        return (
            <ErrorAlert
                title="Ой.. Не удалось загрузить коммерческое предложение.."
                error={error}
            />
        );
    }

    return (
        <Page loading={isLoading}>
            <PageHeader>
                <PageTitle>
                    Коммерческое предложение для
                    <br />
                    {offer?.data?.subjectName || offer?.data?.subjectTaxId}
                </PageTitle>
            </PageHeader>
            <PageContent>
                <Content data={offer?.data?.content} />
                <AgreeButton
                    size="giant"
                    sx={{
                        alignSelf: 'center',
                    }}
                />
                <RenderManager data={offer?.data?.manager}>
                    {({ data }) => (
                        <>
                            <Divider />
                            <Stack spacing={3}>
                                <Typography variant="h6">
                                    Предложение сформировано:
                                </Typography>
                                <Manager data={data} />
                            </Stack>
                        </>
                    )}
                </RenderManager>
            </PageContent>
        </Page>
    );
};

export default Offer;
