import { Outlet } from 'react-router-dom';
import { DefaultFooter } from 'views/common';
import { Layout, ContentLayout, FooterLayout } from './common';
import CloudsFrame from './CloudsFrame';

const LoginPageLayout = () => (
    <Layout sx={{ position: 'relative' }}>
        <CloudsFrame
            display={{
                xs: 'none',
                sm: 'block',
            }}
        />
        <ContentLayout
            maxWidth="xs"
            sx={{
                mt: 8,
            }}
        >
            <Outlet />
        </ContentLayout>
        <FooterLayout>
            <DefaultFooter />
        </FooterLayout>
    </Layout>
);

export default LoginPageLayout;
