import { TypographyOptions } from '@mui/material/styles/createTypography';

const typography: TypographyOptions = {
    fontFamily: 'Roboto Slab, sans-serif',
    subtitle2: {
        fontSize: '1rem',
    },
};

export default typography;
