// api
const { REACT_APP_HOST_URL, REACT_APP_API_URL } = process.env;

export const HOST_URL = REACT_APP_HOST_URL!;
export const API_URL = REACT_APP_API_URL!;

export const FILES_URL = 'https://the-best-bank.pscb.ru/files'; // TODO
export const CURRENCY_INFO_URL = '/data/currencyInfo.json';
export const EMPLOYEE_PHOTO_URL =
    'https://start.pscb.ru/public/v1/files/employees';

export const LEGAL_DBS_URL = 'https://business.faktura.ru/f2b/?site=pscb';
export const RDP_WIKI_URL =
    'https://ru.wikipedia.org/wiki/Remote_Desktop_Protocol';
export const USB_TOKEN_WIKI_URL =
    'https://ru.wikipedia.org/wiki/Токен_(авторизации)';
export const SMART_KEY_WIKI_URL =
    'https://ru.wikipedia.org/wiki/Электронный_ключ';

// links
export const BANK_URL = 'https://www.pscb.ru';
export const OFFICES_LINK = `${BANK_URL}/locations/offices/`;
export const INTERNET_BANKING_URL =
    'https://business.faktura.ru/f2b/?site=pscb';
export const INTERNET_BANKING_DEMO_URL =
    'https://demo.faktura.ru/business/main/?site=pscb';
export const FINANCIAL_INDICATORS =
    'http://www.pscb.ru/about/reports/deposits_xml.php';
export const VALUABLE_PAPERS_INFO_URL =
    'http://www.pscb.ru/informatsii-bumag/index.php';
export const ASV_URL = 'http://www.pscb.ru/about/asv/';
export const PSCB_PLUS_EMAIL = 'plus@pscb.ru';
export const VED_MANAGER = 'iimoiseenko@pscb.ru';
export const SELF_EMPLOYED_EXCEL_TPL = '/documents/self-employed.xlsx';
export const VO_URL = 'https://cse2.pscb.ru/';
export const CURRENCY_API_URL =
    'https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/rub.json';
export const ABC_URL = 'https://abc.pscb.ru';
