import { TableSortLabel, TableSortLabelProps } from '@mui/material';
import { SortDirection } from 'enums';
import { toggleSortDirection } from 'helpers/sort';
import { useCallback, useMemo } from 'react';
import { Sort } from 'types';
import ColumnHeader from './ColumnHeader';
import TableColumn from './TableColumn';
import TableProps from './TableProps';

type ColumnSortCellProps<TData> = Pick<
    TableProps<TData>,
    'variables' | 'onSort'
> & {
    column: TableColumn<TData>;
};

type MuiDirection = TableSortLabelProps['direction'];

const getNextDirection = (sort: Sort, property: string): SortDirection => {
    return property === sort?.property
        ? toggleSortDirection(sort?.direction)
        : SortDirection.ASC;
};

const getMuiDirection = (sort: Sort, property: string): MuiDirection => {
    return sort?.property === property
        ? (sort?.direction?.toLocaleLowerCase() as MuiDirection)
        : 'asc';
};

const ColumnSortCell = function <TData>({
    column,
    variables,
    onSort,
}: ColumnSortCellProps<TData>) {
    const sort = variables?.sort;
    const [active, direction] = useMemo(() => {
        return [
            sort?.property === column.field,
            getMuiDirection(sort!, column.field!),
        ];
    }, [sort, column]);

    const handleClick = useCallback(() => {
        const property = column.field!;
        const nextSort: Sort = {
            property,
            direction: getNextDirection(sort!, property),
        };
        onSort!(nextSort);
    }, [column, sort, onSort]);

    return (
        <TableSortLabel
            active={active}
            direction={direction}
            onClick={handleClick}
        >
            <ColumnHeader {...column} />
        </TableSortLabel>
    );
};

export default ColumnSortCell;
