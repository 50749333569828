import { GroupedBindingStates } from 'enums';
import { GroupedBindingState } from 'types';

export enum BindingState {
    NEW = 'NEW',
    EXPORTED = 'EXPORTED',
    CONFLICT = 'CONFLICT',
}

const { NEW, EXPORTED, CONFLICT } = BindingState;
const { SUCCESS, ERROR, ALL } = GroupedBindingStates;

const groupedBindingStates: { [key: string]: GroupedBindingState } = {
    [ALL]: {
        code: ALL,
        displayName: 'во всех статусах',
        states: [NEW, EXPORTED, CONFLICT],
        color: 'default',
    },
    [SUCCESS]: {
        code: SUCCESS,
        displayName: 'всё хорошо',
        states: [NEW, EXPORTED],
        color: 'success',
    },
    [ERROR]: {
        code: ERROR,
        displayName: 'ошибка',
        states: [CONFLICT],
        color: 'error',
    },
};

export default groupedBindingStates;
