import { Avatar, AvatarProps } from '@mui/material';
import { getFullName } from 'helpers/format';
import { HumanName } from 'react-query-client/dto';

const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
};

type StringAvatarProps = AvatarProps & {
    value: HumanName;
};

const StringAvatar: React.FC<StringAvatarProps> = ({ value, ...props }) => {
    const fullName = getFullName(value);
    const shortName = value.lastName[0];
    return (
        <Avatar {...props} sx={{ bgcolor: stringToColor(fullName) }}>
            {shortName}
        </Avatar>
    );
};

export default StringAvatar;
