import { mapValues, reduce, some } from 'lodash';
import { ApiResponse } from 'types';

// for example:
// const response = createResponse({
//     offer: {
//         data: {
//             id: 1
//         }
//     },
//     appointment: {
//         data: {
//             id: 2
//         },
//         meta: {
//             a: 2
//         }
//     }
// });

// output:
// {
//     data: {
//         offer: {
//             id: 1,
//         },
//         appointment: {
//             id: 2,
//         },
//     },
//     meta: {
//         offer: undefined,
//         appointment: {
//             a: 2,
//         },
//     },
// }

type Payload = Record<string, ApiResponse<any>>;

const createMultiResponse = (data: Payload) => {
    const responseFields = ['data', 'meta', 'error'];
    return reduce(
        responseFields,
        (result, field) => {
            const responseField = field as keyof ApiResponse;
            const hasValue = some(data, (item) => !!item?.[responseField]);
            return {
                ...result,
                ...(hasValue
                    ? {
                          [field]: mapValues(
                              data,
                              (item) => item?.[responseField]
                          ),
                      }
                    : {}),
            };
        },
        {} as ApiResponse
    );
};

export default createMultiResponse;
