import { CircularProgress, CircularProgressProps } from '@mui/material';
import { circularProgressClasses } from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

const Progress = styled((props: CircularProgressProps) => (
    <CircularProgress disableShrink {...props} />
))({
    [`& .${circularProgressClasses.root}`]: {
        animation: `$progress-rotate 1s linear infinite`,
    },
    '@keyframes progress-rotate': {
        '0%': {
            transform: 'rotate(0)',
        },
        '100%': {
            transform: 'rotate(360deg)',
        },
    },
});

export default Progress;
