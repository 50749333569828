import isEmpty from './isEmpty';

type FnCondition = <E>(element: E) => boolean;
const isNotEmpty = <E>(element: E) => !isEmpty(element);

const insertIf = <E = any>(
    element: E,
    condition: FnCondition | boolean = isNotEmpty
) => {
    return (typeof condition === 'function' ? condition(element) : condition)
        ? [element]
        : [];
};

export default insertIf;
