export enum SignStatus {
    EXPIRED = 'EXPIRED',
    INVALID_TOKEN = 'INVALID_TOKEN',
    INVALID_CODE = 'INVALID_CODE',
    ALREADY_USED = 'ALREADY_USED',
    INVALIDATED = 'INVALIDATED',
    OK = 'OK',
    UNDEFINED = 'UNDEFINED',
}

const signStatuses: Record<SignStatus, string> = {
    [SignStatus.EXPIRED]:
        'Вы ввели код подтверждения, срок действия которого уже истек',
    [SignStatus.INVALID_TOKEN]: 'Использован некорректный токен',
    [SignStatus.INVALID_CODE]: 'Вы ввели некорректный код подтверждения',
    [SignStatus.ALREADY_USED]:
        'Вы ввели код подтверждения, который уже был использован',
    [SignStatus.INVALIDATED]: 'Вы ввели недействительный код подтверждения',
    [SignStatus.OK]: 'успех',
    [SignStatus.UNDEFINED]: 'не определен',
};

export default signStatuses;
