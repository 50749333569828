import { findIndex, map } from 'lodash';
import { get } from 'helpers';
import { currencies } from 'configs';
import { Currency, Sorter } from 'types';

const currenciesArr: Currency[] = map(currencies, (item) => item);

const getOrder = (hosting: object, field: string): number => {
    const currency: string = get(hosting, field);
    return (
        findIndex(currenciesArr, (item: Currency) => item.code === currency) ||
        -1
    );
};

const currencySorter =
    (field: string): Sorter =>
    (a, b) =>
        getOrder(a, field) - getOrder(b, field);

export default currencySorter;
