import { Link, LinkProps } from '@mui/material';
import React from 'react';
import {
    LinkProps as RouterLinkProps,
    Link as RouterDomLink,
} from 'react-router-dom';

type CustomRouterLinkProps = LinkProps & RouterLinkProps;

const RouterLink = React.forwardRef<any, CustomRouterLinkProps>(
    ({ children, ...rest }, ref) => (
        <Link ref={ref} component={RouterDomLink} {...rest}>
            {children}
        </Link>
    )
);

export default RouterLink;
