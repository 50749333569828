import { FormSpy } from 'react-final-form';
import LoadingButton, { LoadingButtonProps } from './LoadingButton';

const subscription = {
    valid: true,
    pristine: true,
    submitting: true,
    dirtySinceLastSubmit: true,
    hasValidationErrors: true,
};

const SubmitButton: React.FC<LoadingButtonProps> = (props) => (
    <FormSpy subscription={subscription}>
        {({
            valid,
            pristine,
            submitting,
            hasValidationErrors,
            dirtySinceLastSubmit,
        }) => {
            const allowSubmit =
                valid || (dirtySinceLastSubmit && !hasValidationErrors);
            return (
                <LoadingButton
                    type="submit"
                    color={allowSubmit ? 'primary' : 'inherit'}
                    loading={submitting}
                    disabled={submitting}
                    variant="contained"
                    {...props}
                />
            );
        }}
    </FormSpy>
);

export default SubmitButton;
