import { isAxiosError } from 'axios';
import { toString } from 'lodash';

const getErrorMessage = (error: unknown): string => {
    if (isAxiosError(error)) {
        const { message, config, response } = error;
        const { baseURL, url, method } = config || {};
        const {
            error: backError,
            message: backMessage,
            status,
        } = response?.data || {};
        return `${message}\n${method?.toLocaleUpperCase()} ${baseURL}${url}\n${
            backError ? `${backError}: ${backMessage || status}` : ''
        }`;
    } else if (error instanceof Error) {
        return error.message;
    } else {
        return toString(error);
    }
};

export default getErrorMessage;
