import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { loggedInSelector, tokenDataSelector } from 'state/loggedIn';
import callApi from './callApi';

const useRegVisit = () => {
    const loggedIn = useRecoilValue(loggedInSelector);
    const tokenData = useRecoilValue(tokenDataSelector);

    useEffect(() => {
        if (loggedIn) {
            callApi('setUserID', tokenData?.userId);
        }
    }, [loggedIn, tokenData]);
};

export default useRegVisit;
