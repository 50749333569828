import { Stack } from '@mui/material';
import AdminContacts from './AdminContacts';
// import Apps from './Apps';
import BankLink from './BankLink';
import Demo from './Demo';

const OfferFooter = () => (
    <Stack spacing={3} marginBottom="20px">
        <AdminContacts />
        <Demo />
        <BankLink />
        {/* <Stack
            direction="row"
            justifyContent="space-between"
            spacing={2}
            flexWrap="nowrap"
        >
            <BankLink />
            <Apps />
        </Stack> */}
    </Stack>
);

export default OfferFooter;
