import ComponentsTheme from './ComponentsTheme';

const MuiLink: ComponentsTheme['MuiLink'] = {
    styleOverrides: {
        root: {
            color: '#06c',
            textDecorationColor: '#06c',
            cursor: 'pointer',
        },
    },
    defaultProps: {
        underline: 'hover',
    },
};

export default MuiLink;
