import { Branch } from 'enums';

export enum AccountType {
    DEFAULT = 'DEFAULT',
    RKO = 'RKO',
    PAYMENT = 'PAYMENT',
}

export enum AccountState {
    ACTIVE = 'Active',
    CLOSED = 'Closed',
    RESERVED = 'Reserved',
}

type Account = {
    currency: string;
    name: string;
    num: string;
    branch: Branch;
    state: AccountState;
    type: AccountType;
};

export default Account;
