enum PabContractUIState {
    NEW = 'NEW',
    APPROVAL = 'APPROVAL',
    IN_PROCESSING = 'IN_PROCESSING',
    ACTIVE = 'ACTIVE',
    REFUSED = 'REFUSED',
    UNDEFINED = 'UNDEFINED',
}

export default PabContractUIState;
