import { isAxiosError } from 'axios';

const isStatus = (error: unknown, ...statuses: number[]) => {
    return (
        isAxiosError(error) &&
        statuses.some((status) => error.response?.status === status)
    );
};

export default isStatus;
