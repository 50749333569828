import { Info } from 'components';
import TableColumn from './TableColumn';
import { Stack } from '@mui/material';

type ColumnHeaderProps<TData> = Pick<
    TableColumn<TData>,
    'headerName' | 'info' | 'align'
>;

const getJustifyContent = function <TData>(
    align: ColumnHeaderProps<TData>['align'] = 'left'
) {
    return {
        center: 'center',
        right: 'flex-end',
        left: 'flex-start',
    }[align];
};

const WithInfo = function <TData>({
    headerName,
    info,
    align,
}: ColumnHeaderProps<TData>) {
    const justifyContent = getJustifyContent(align);
    return (
        <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent={justifyContent}
        >
            <span>{headerName}</span>
            <Info iconSize="small">{info}</Info>
        </Stack>
    );
};

const ColumnHeader = function <TData>(props: ColumnHeaderProps<TData>) {
    const { info, headerName } = props;
    return info ? <WithInfo {...props} /> : <span>{headerName}</span>;
};

export default ColumnHeader;
