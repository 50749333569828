import { isAxiosError } from 'axios';
import signStatuses from 'configs/signStatuses';
import { ApiResponse, VerificationResult } from 'types';
import isStatus from './isStatus';

const parseVerificationError = (
    error: unknown,
    defaultMessage: string = 'Ошибка при проверке кода верификации'
) => {
    let errorMessage = defaultMessage;
    if (isAxiosError(error) && isStatus(error, 422)) {
        const data = error.response?.data as ApiResponse<VerificationResult>;
        if (!data.data.valid) {
            errorMessage = signStatuses[data.data.status];
        }
    }
    return errorMessage;
};

export default parseVerificationError;
