import ComponentsTheme from './ComponentsTheme';

const MuiCssBaseline: ComponentsTheme['MuiCssBaseline'] = {
    styleOverrides: `
        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus {
            -webkit-box-shadow: 0 0 0 60px white inset !important;
            background-color: white !important;
            background-clip: content-box !important;
            transition: background-color 5000s ease-in-out 0s;
        }
    `,
};

export default MuiCssBaseline;
