import { Alert, Stack, Typography } from '@mui/material';
import useOfferQuery from './useOfferQuery';
import { Page, PageContent, PageHeader, PageTitle } from 'components';
import RenderManager from './RenderManager';
import Manager from './Manager';
import { getOfferManagerPhotoUrl } from 'helpers/url';

const DeclinedOffer = () => {
    const { data: offer } = useOfferQuery();
    return (
        <Page>
            <PageHeader>
                <PageTitle>
                    {offer?.data?.subjectName || offer?.data?.subjectTaxId}
                </PageTitle>
            </PageHeader>
            <PageContent>
                <Alert severity="warning">
                    К сожалению, мы не смогли завершить процедуру открытия счета
                </Alert>
                <RenderManager data={offer?.data.manager}>
                    {({ data: manager }) => (
                        <Stack spacing={2}>
                            <Typography>
                                Вы можете связаться с менеджером для уточнения
                                причин:
                            </Typography>
                            <Manager
                                data={{
                                    ...manager!,
                                    photoUrl: getOfferManagerPhotoUrl(
                                        offer?.data.accessId!
                                    ),
                                }}
                            />
                        </Stack>
                    )}
                </RenderManager>
            </PageContent>
        </Page>
    );
};

export default DeclinedOffer;
