import { Button, ButtonProps } from '@mui/material';
import Progress from './Progress';

// @mui/lab contains LoadingButton
// MuiLoadingButton default props (global theme) not working

export type LoadingButtonProps = ButtonProps & {
    /**
     * If `true`, the loading indicator is shown.
     */
    loading?: boolean;
};

const LoadingButton: React.FC<LoadingButtonProps> = ({
    loading,
    disabled,
    startIcon,
    ...rest
}) => (
    <Button
        {...rest}
        disabled={disabled || loading}
        startIcon={
            loading ? (
                <Progress
                    size={rest.size === 'small' ? 14 : 18}
                    color="inherit"
                />
            ) : (
                startIcon
            )
        }
    />
);

export default LoadingButton;
