import { IMask } from 'react-imask';

const formatPhone = (value?: string) => {
    if (!value) return '';
    const masked = IMask.createMask({
        mask: '+7 (000) 000-00-00',
    });
    masked.resolve(value);
    return masked.value;
};

export default formatPhone;
