import React, { createElement, ReactNode, useCallback } from 'react';

type Props = {
    component?: string;
    children?: ReactNode;
};

const StopClickEvent: React.FC<Props> = ({ component = 'span', children }) => {
    const onClick = useCallback((event: Event) => {
        event.stopPropagation();
        if ((event.target as HTMLElement).tagName !== 'A') {
            event.preventDefault();
        }
    }, []);
    return createElement(component, { onClick }, children);
};

export default StopClickEvent;
