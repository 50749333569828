import isEmpty from 'helpers/isEmpty';
import { numericFormatter } from 'react-number-format';

const formatAmountCustom = (
    value: string | number,
    suffix: string = 'руб.'
) => {
    if (isEmpty(value)) return '';

    const numeric = numericFormatter(value.toString(), {
        thousandSeparator: ' ',
        decimalSeparator: '.',
    });

    return `${numeric} ${suffix}`;
};

export default formatAmountCustom;
