import { getCurrencyConfig } from 'helpers/config';
import { numericFormatter } from 'react-number-format';

const formatAmount = (
    value?: string | number,
    currencyCode: string = 'RUB'
) => {
    if (value === undefined || value === null) return '';

    const numeric = numericFormatter(value.toString(), {
        thousandSeparator: ' ',
        decimalSeparator: '.',
    });
    const currency = getCurrencyConfig(currencyCode);

    return `${numeric} ${currency.symbol}`;
};

export default formatAmount;
