import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactNode } from 'react';

export type AlertModalProps = {
    children: ReactNode;
    open: boolean;
    title?: string;
    okText?: string;
    onClose: () => void;
};

const AlertModal: React.FC<AlertModalProps> = ({
    title,
    children,
    open,
    okText,
    onClose,
}) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Dialog
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={onClose}
        >
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained" color="primary">
                    {okText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AlertModal.defaultProps = {
    okText: 'Ок',
};

export default AlertModal;
