import { KeyboardArrowUp } from '@mui/icons-material';
import { Box, Fab, Zoom, useScrollTrigger } from '@mui/material';
import { useCallback } from 'react';
import noPrint from 'theme/noPrint';

const ScrollToTopFab = () => {
    const trigger = useScrollTrigger({
        target: window,
        disableHysteresis: true,
        threshold: 300,
    });

    const handleClick = useCallback(() => {
        const anchor = document.documentElement;
        if (anchor) {
            anchor.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, []);

    return (
        <Zoom in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: 'fixed', bottom: 20, right: 20, ...noPrint }}
            >
                <Fab color="primary" size="small" aria-label="На верх">
                    <KeyboardArrowUp />
                </Fab>
            </Box>
        </Zoom>
    );
};

export default ScrollToTopFab;
