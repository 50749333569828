import { Logo } from 'components';
import { useCurrentClientId } from 'hooks';

const UserPageLogo = () => {
    const currentClientId = useCurrentClientId();
    const path = currentClientId
        ? `/clients/${currentClientId}`
        : '/add-new-client';
    return <Logo logo="logo.svg?5" rootPath={path} />;
};

export default UserPageLogo;
