import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Branch } from 'enums';
import { api } from 'react-query-client';
import { ApiResponse } from 'types';
import produce from 'immer';
import { filter } from 'lodash';
import { bankRequisites } from 'configs';
import { useCallback } from 'react';

export type OfficeType = {
    id: number;
    city: string;
    metro: string;
    address: string;
};

type OfficesResponse = ApiResponse<OfficeType[]>;

export const fetchOffices = async () =>
    (await api.get<OfficesResponse>('/cmp/offices')).data;

export const filterByBranch = (data: OfficesResponse, branch?: Branch) => {
    if (!branch) return data;
    return produce(data, (draft) => {
        draft.data = filter(draft.data, (office) => {
            return office.city === bankRequisites[branch].city.value;
        });
    });
};

const getOfficesKey = (branch?: Branch) => {
    const key = ['offices'];
    if (branch) {
        key.push(branch);
    }
    return key;
};

export const useFetchOffices = () => {
    const queryClient = useQueryClient();
    return useCallback(
        async (branch?: Branch) => {
            return await queryClient
                .fetchQuery<OfficesResponse>({
                    queryKey: getOfficesKey(branch),
                    queryFn: fetchOffices,
                })
                .then((data) => filterByBranch(data, branch));
        },
        [queryClient]
    );
};

const useOfficesQuery = (branch?: Branch) => {
    return useQuery<OfficesResponse>({
        queryKey: getOfficesKey(branch),
        queryFn: fetchOffices,
        select: (data) => (branch ? filterByBranch(data, branch) : data),
    });
};

export default useOfficesQuery;
