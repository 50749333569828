import { Typography } from '@mui/material';
import { isEmpty } from 'helpers';
import { map, size } from 'lodash';
import React from 'react';

const mbSize = (value: number): string => {
    const BYTES = 1024;
    return (value / BYTES / BYTES).toFixed(1);
};

type InvalidFilesProps = {
    files?: File[] | FileList;
};

const InvalidFiles: React.FC<InvalidFilesProps> = ({ files }) => {
    if (isEmpty(files)) {
        return null;
    }
    const multiple = size(files) > 1;
    const filesTags = map(
        files,
        ({ name, size }) => `${name} (${mbSize(size)} Мб)`
    );
    const filesOutput = filesTags.join(', ');
    return (
        <Typography variant="caption" color="error">
            Недопустимый размер {`файл${multiple ? 'ов' : 'а'}`}: {filesOutput}
        </Typography>
    );
};

export default InvalidFiles;
