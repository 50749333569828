import { useMemo, useState } from 'react';

export type UseShowProps = [
    show: boolean,
    onShow: () => void,
    onClose: () => void,
    onToggle: () => void
];

const useShow = (defaultShow = false): UseShowProps => {
    const [show, setShow] = useState(defaultShow);

    return useMemo(() => {
        const onShow = () => setShow(true);
        const onClose = () => setShow(false);
        const onToggle = () => setShow(!show);
        return [show, onShow, onClose, onToggle];
    }, [show]);
};

export default useShow;
