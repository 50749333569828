import { Box } from '@mui/system';
import { ReactNode } from 'react';

type FullWidthRowProps = {
    columnsCount: number;
    children?: ReactNode;
};

const FullWidthRow: React.FC<FullWidthRowProps> = ({
    columnsCount,
    children,
}) => (
    <tr>
        <Box component="td" p={0} colSpan={columnsCount}>
            {children}
        </Box>
    </tr>
);

export default FullWidthRow;
