import { Close } from '@mui/icons-material';
import {
    Box,
    Divider,
    IconButton,
    Drawer as MuiDrawer,
    DrawerProps as MuiDrawerProps,
    Stack,
    Typography,
} from '@mui/material';
import { Fragment } from 'react';

export type DrawerProps = MuiDrawerProps & {
    /**
     * Drawer title text.
     */
    title?: string;
    /**
     * Drawer body width.
     * @default 40%
     */
    width?: string;
    /**
     * Drawer body height.
     * @default 100%
     */
    height?: string;
    /**
     * Drawer body max width.
     */
    maxWidth?: string;
};

const Drawer: React.FC<DrawerProps> = ({
    title,
    width = '40%',
    height = '100%',
    anchor = 'right',
    maxWidth,
    children,
    onClose,
    ...rest
}) => {
    const handleClose = (event: React.MouseEvent) => {
        onClose && onClose(event, 'escapeKeyDown');
    };

    const PaperProps = {
        ...rest.PaperProps,
        sx: {
            ...rest.PaperProps?.sx,
            width,
            height,
            maxWidth,
        },
    };

    return (
        <MuiDrawer
            anchor={anchor}
            PaperProps={PaperProps}
            onClose={onClose}
            {...rest}
        >
            <Stack height="100%">
                {title && (
                    <Fragment>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            padding="16px 24px"
                        >
                            <Typography variant="subtitle1">{title}</Typography>
                            <IconButton size="small" onClick={handleClose}>
                                <Close fontSize="small" />
                            </IconButton>
                        </Stack>
                        <Divider />
                    </Fragment>
                )}
                <Box padding="16px 24px" height="100%">
                    {children}
                </Box>
            </Stack>
        </MuiDrawer>
    );
};

export default Drawer;
