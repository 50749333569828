import { useInView } from 'react-intersection-observer';
import Table from './Table';
import TableProps from './TableProps';
import { useEffect } from 'react';
import { TableFooter } from '@mui/material';
import FullWidthRow from './FullWidthRow';
import LinearLoading from 'components/LinearLoading';
import getColumnsCount from './getColumnsCount';

type InfinityTableProps = Omit<TableProps, 'data'> & {
    data: any; // TODO
    onLoadMore: () => void;
};

const InfinityTable: React.FC<InfinityTableProps> = ({ onLoadMore, ...rest }) => {
    const { ref, inView } = useInView();
    const columnsCount = getColumnsCount(rest);

    useEffect(() => {
        if (inView) {
            onLoadMore();
        }
    }, [inView, onLoadMore]);

    return (
        <>
            {/* TODO */}
            {/* @ts-ignore */}
            <Table
                {...rest}
                footer={(params) => (
                    <TableFooter ref={ref}>
                        <FullWidthRow columnsCount={columnsCount}>
                            <LinearLoading loading={params.loading} transitionDelay={0} />
                        </FullWidthRow>
                    </TableFooter>
                )}
            />
        </>
    );
};

export default InfinityTable;
