import jwtDecode from 'jwt-decode';
import { Token } from 'types';

export const parseToken = (value: string | null): Token | null => {
    try {
        return jwtDecode(value!) as Token;
    } catch (error) {
        return null;
    }
};

export const tokenTimeout = (
    token: string | null,
    callback: () => void
): NodeJS.Timeout | undefined => {
    const now = new Date().getTime();
    const tokenData = parseToken(token);

    if (!tokenData) {
        return;
    }

    const expire = tokenData.exp * 1000;

    if (now >= expire) {
        return;
    }

    const duration = expire - now;

    return setTimeout(callback, duration);
};
