import ComponentsTheme from './ComponentsTheme';

const MuiMenu: ComponentsTheme['MuiMenu'] = {
    defaultProps: {
        disableAutoFocusItem: true,
        disableScrollLock: true
    },
};

export default MuiMenu;
