import { PSCB_PLUS_EMAIL } from 'configs/urls';
import { getErrorMessage, getSupportMessage, mailtoHref } from 'helpers/format';
import { FallbackProps } from 'react-error-boundary';

const ErrorBoundaryFallback: React.FC<FallbackProps> = ({ error }) => {
    const errorMessage = getErrorMessage(error);
    const supportMessage = getSupportMessage(errorMessage);
    const supportHref = mailtoHref(
        PSCB_PLUS_EMAIL,
        'Ошибка при работе в системе ПСКБ+',
        supportMessage
    );
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'center',
                fontFamily: 'Roboto Slab, sans-serif',
                paddingTop: '60px',
                gap: '60px',
                color: '#4d4f53',
            }}
        >
            <img
                src="/images/error-boundary.svg?1"
                alt="error boundary"
                style={{
                    width: '100%',
                    maxWidth: '540px',
                    height: 'auto',
                }}
            />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <h4
                    style={{
                        fontSize: '2rem',
                        lineHeight: '1.235',
                        fontWeight: 400,
                        textAlign: 'center',
                        marginTop: '0px',
                        marginBottom: '0.35em',
                    }}
                >
                    Ой.. что-то сломалось..
                </h4>
                <p
                    style={{
                        fontSize: '1em',
                        fontWeight: 300,
                        textAlign: 'center',
                    }}
                >
                    Не волнуйтесь, мы сейчас разберемся
                </p>
                <pre
                    style={{
                        marginBottom: '35px',
                        fontFamily:
                            'ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace',
                        padding: '16px',
                        lineHeight: 1.45,
                        backgroundColor: '#eef4fa',
                        borderRadius: '6px',
                        maxWidth: '500px',
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-word',
                    }}
                >
                    {errorMessage}
                </pre>
                <div style={{ display: 'flex', alignItems: '`center' }}>
                    <a
                        href={supportHref}
                        style={{
                            color: '#06c',
                            textDecoration: 'none',
                            cursor: 'pointer',
                        }}
                    >
                        Сообщить о проблеме
                    </a>
                    <a
                        href="/"
                        style={{
                            color: '#06c',
                            textDecoration: 'none',
                            cursor: 'pointer',
                            marginLeft: '16px',
                        }}
                    >
                        Перезагрузить страницу
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ErrorBoundaryFallback;
