enum PabContractStateType {
    CREATED = 'CREATED', // клиент создал заявку на договор
    CONFIRMED = 'CONFIRMED', // манагеры подтвердили заявку, контракт сначала назначается на рассмотрение конкретному агенту
    AGENT_ACCEPTED = 'AGENT_ACCEPTED', // агент согласился контракт и выставил курс
    AGENT_REJECTED = 'AGENT_REJECTED', // агент отверг заявку на договор
    RATE_SET = 'RATE_SET', // манагеры выставили курс с учётом курса агента
    ACTIVATED = 'ACTIVATED', // клиент согласился на курс и конечный контракт
    DISCARDED = 'DISCARDED', // клиент отказался от договора
    INVALIDATED = 'INVALIDATED', // клиенту отказали
}

export default PabContractStateType;
