import { formatAccNum } from 'helpers/format';

type AccountNumProps = {
    /**
     * Account number.
     */
    value?: string;
};

const AccountNum: React.FC<AccountNumProps> = ({ value }) => {
    let formattedNum = '';
    if (value) {
        formattedNum = formatAccNum(value);
    }
    return <span>{formattedNum}</span>;
};

export default AccountNum;
