import { Box, BoxProps, StackProps, Stack } from '@mui/material';

export const HeaderToolbarLayoutSearch: React.FC<BoxProps> = (props) => (
    <Box flexGrow={1} display={{ xs: 'none', md: 'flex' }} {...props} />
);

export const HeaderToolbarLayoutContent: React.FC<StackProps> = (props) => (
    <Stack
        direction="row"
        spacing={{
            xs: 0,
            sm: 1,
        }}
        alignItems="center"
        {...props}
    />
);

const HeaderToolbarLayout: React.FC<StackProps> = ({ children, ...rest }) => (
    <Stack
        direction="row"
        spacing={{
            xs: 2,
            sm: 4,
        }}
        alignItems="center"
        justifyContent="flex-end"
        sx={{
            flexGrow: 1,
        }}
        {...rest}
    >
        {children}
    </Stack>
);

export default HeaderToolbarLayout;
