import {
    Chip,
    IconButton,
    Stack,
    StackProps,
    Typography,
    TypographyProps,
} from '@mui/material';
import { ArrowLeft } from 'components/icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PageSkeleton from './PageSkeleton';

export const PageHeader: React.FC<StackProps> = (props) => (
    <Stack direction="row" alignItems="center" {...props} />
);

type PageHeaderWithGoBackProps = StackProps & {
    goBackPath?: string;
};

export const PageHeaderWithGoBack: React.FC<PageHeaderWithGoBackProps> = ({
    goBackPath,
    ...rest
}) => {
    const navigate = useNavigate();
    const handleGoBack = () => {
        if (goBackPath) {
            navigate(goBackPath);
        } else {
            navigate(-1);
        }
    };
    return (
        <Stack spacing={2}>
            <IconButton
                sx={{ alignSelf: 'flex-start', marginLeft: '-7px' }}
                title="назад"
                onClick={handleGoBack}
            >
                <ArrowLeft />
            </IconButton>
            <PageHeader {...rest} />
        </Stack>
    );
};

export const PageTitle: React.FC<TypographyProps> = (props) => (
    <Typography variant="h5" sx={{ mt: '-7px' }} {...props} />
);

export const PageTitleWithCount: React.FC<
    TypographyProps & { count?: number }
> = ({ children, count, ...rest }) => (
    <Stack direction="row" spacing={2}>
        <PageTitle display="flex" {...rest}>
            {children}
        </PageTitle>
        {!!count && (
            <Chip
                label={count}
                sx={{ fontWeight: 600, fontSize: '18px' }}
                title="всего"
            />
        )}
    </Stack>
);

export const PageContent: React.FC<StackProps> = (props) => (
    <Stack spacing={5} {...props} />
);

type PageProps = StackProps & {
    loading?: boolean;
};

const Page: React.FC<PageProps> = ({ loading, ...rest }) => {
    return loading ? (
        <PageSkeleton />
    ) : (
        <Stack spacing={{ xs: 3, sm: 5 }} {...rest} />
    );
};

export default Page;
