import { atomFamily } from 'recoil';

export const openPopupState = atomFamily<boolean, string>({
    key: 'openPopup',
    default: false,
});

export const recordPopupState = atomFamily<any, string>({
    key: 'recordPopup',
    default: undefined,
});
