import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

const StyledTable = styled('table')`
    border-collapse: collapse;
    tbody > tr > th,
    td {
        padding: 8px 10px;
        &:nth-of-type(1) {
            padding-left: 0px;
        }
    }
`;

const SimpleTable: React.FC<{ children: ReactNode }> = ({
    children,
    ...rest
}) => {
    return (
        <StyledTable {...rest}>
            <tbody>{children}</tbody>
        </StyledTable>
    );
};

export default SimpleTable;
