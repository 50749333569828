import { Branch } from 'enums';

const bankRequisites = {
    [Branch.SPB]: {
        bankCorrNumber: {
            title: 'Корр.счет банка',
            value: '30101810000000000852 в Северо-Западном ГУ Банка России',
        },
        bankInn: {
            title: 'ИНН банка',
            value: '7831000965',
        },
        bankBik: {
            title: 'БИК банка',
            value: '044030852',
        },
        oktmo: {
            title: 'ОКТМО',
            value: '40911000',
        },
        okpo: {
            title: 'ОКПО',
            value: '31029552',
        },
        kpp: {
            title: 'КПП',
            value: '784201001',
        },
        name: {
            title: 'Наименование',
            value: 'АО БАНК "ПСКБ"',
        },
        swift: {
            title: 'SWIFT',
            value: 'PSOCRU2P',
        },
        city: {
            title: 'Город',
            value: 'Санкт-Петербург',
        },
    },
    [Branch.MSK]: {
        bankCorrNumber: {
            title: 'Корр.счет банка',
            value: '30101810345250000606 в ГУ Банка России по Центральному федеральному округу',
        },
        bankInn: {
            title: 'ИНН банка',
            value: '7831000965',
        },
        bankBik: {
            title: 'БИК банка',
            value: '044525606',
        },
        oktmo: {
            title: 'ОКТМО',
            value: '45375000',
        },
        okpo: {
            title: 'ОКПО',
            value: '71623549',
        },
        kpp: {
            title: 'КПП',
            value: '770143001',
        },
        name: {
            title: 'Наименование',
            value: 'Московский филиал Акционерного общества «Петербургский социальный коммерческий банк»',
        },
        swift: {
            title: 'SWIFT',
            value: 'PSOCRU2P',
        },
        city: {
            title: 'Город',
            value: 'Москва',
        },
    },
};

export default bankRequisites;
