import { styled } from '@mui/material/styles';
import ReactMarkdown from 'react-markdown';
import { OfferContent } from 'types/Offer';
import { Data } from 'types';
import { Typography } from '@mui/material';

const Wrapper = styled('div')`
    h4 {
        font-size: 1.25rem;
        font-weight: 600;
    }
    a {
        color: #06c;
        text-decoration: none;
        cursor: pointer;
    }
`;

const Content: React.FC<Data<OfferContent>> = ({ data }) => {
    const localizedContent = data?.['ru'] || '';
    return (
        <Wrapper>
            <Typography>
                Мы готовы обсуждать и уточнять все позиции данного предложения в
                удобное для Вас время.
            </Typography>
            <ReactMarkdown children={localizedContent} />
        </Wrapper>
    );
};

export default Content;
