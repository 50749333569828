import { Typography, Box, Alert, Stack } from '@mui/material';
import { Href, Page, PageContent, PageHeader, PageTitle } from 'components';
import Manager from 'views/common/Manager';
import { getOfferManagerPhotoUrl } from 'helpers/url';
import { Data, Offer } from 'types';
import { RenderManager } from 'views/common';

const Expired: React.FC<Data<Offer>> = ({ data }) => (
    <Page>
        <PageHeader>
            <PageTitle>{data?.subjectName || data?.subjectTaxId}</PageTitle>
        </PageHeader>
        <PageContent>
            <Alert severity="warning">
                Срок действия коммерческого предложения истёк
            </Alert>
            <Stack spacing={2}>
                <Box>
                    <Href
                        href="https://www.pscb.ru/corp/services/open-account"
                        target="_self"
                    >
                        Отправьте заявку на открытие счета
                    </Href>{' '}
                    <Typography component="span">
                        или свяжитесь с Вашим менеджером:
                    </Typography>
                </Box>
                <RenderManager data={data?.manager}>
                    {({ data: manager }) => (
                        <Manager
                            data={{
                                ...manager!,
                                photoUrl: getOfferManagerPhotoUrl(
                                    data?.accessId!
                                ),
                            }}
                        />
                    )}
                </RenderManager>
            </Stack>
        </PageContent>
    </Page>
);

export default Expired;
