import { Box, Link, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { RouterLink } from 'components';

const NoMatch = () => (
    <Grid container spacing={4}>
        <Grid
            xs={12}
            md={6}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap={2}
        >
            <Typography variant="h2" fontWeight={600}>
                Ой..
            </Typography>
            <Typography variant="body1" align="center">
                Нам не удалось найти такую страницу...
            </Typography>
            <Link component={RouterLink} to="/">
                на главную
            </Link>
        </Grid>
        <Grid xs={12} md={6} display="flex" justifyContent="center">
            <Box
                component="img"
                src="/images/no-match.svg?1"
                alt="no-match"
                width="100%"
                maxWidth={{
                    xs: '300px',
                    sm: '500px',
                }}
                height="auto"
            />
        </Grid>
    </Grid>
);

export default NoMatch;
