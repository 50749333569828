import { LinearProgress, Fade, LinearProgressProps } from '@mui/material';

type LinearLoadingPorps = LinearProgressProps & {
    /**
     * If `true`, the loading indicator is shown.
     */
    loading?: boolean;

    transitionDelay?: number;
};

const LinearLoading: React.FC<LinearLoadingPorps> = ({
    loading,
    transitionDelay = 200,
    ...rest
}) => {
    return (
        <Fade
            in={loading}
            style={{
                transitionDelay: loading ? `${transitionDelay}ms` : '0ms',
            }}
            unmountOnExit
        >
            <LinearProgress color="primary" {...rest} />
        </Fade>
    );
};

export default LinearLoading;
