const shortenFullName = (value?: string): string => {
    if (value) {
        try {
            const [lastName, firstName, middleName] = value.split(' ');
            return `${lastName} ${firstName[0]}.${middleName[0]}.`;
        } catch (error) {
            return value;
        }
    } else {
        return '';
    }
};

export default shortenFullName;
