import { QueryKey, useInfiniteQuery } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { api as qcApi } from './queryClient';
import { concat } from 'lodash';

const LIMIT = 20;

const usePageableQuery = (queryKey: QueryKey, url: string, api = qcApi) => {
    const [variables, setVariables] = useState({});
    const {
        data,
        isFetching: loading,
        fetchNextPage,
        error,
    } = useInfiniteQuery({
        queryKey: concat(queryKey, variables),
        queryFn: async ({ pageParam }) => {
            const offset = pageParam ? pageParam : 0;
            const input = {
                ...variables,
                page: {
                    limit: LIMIT,
                    offset,
                },
            };
            const { data } = await api.get(`${url}`, {
                params: new URLSearchParams({
                    input: JSON.stringify(input),
                }),
            });
            return data;
        },
        getNextPageParam: (lastPage) => {
            const nextOffset = lastPage.meta?.pageInfo.hasNextPage
                ? lastPage.meta?.pageInfo.offset + LIMIT
                : undefined;
            return nextOffset;
        },
    });

    const onFilter = useCallback((values: Record<string, any>) => {
        setVariables((pv) => ({
            ...pv,
            filter: values,
        }));
    }, []);

    return {
        data,
        loading,
        error,
        onLoadMore: fetchNextPage,
        onFilter,
    };
};

export default usePageableQuery;
