import { useCallback } from 'react';
import { ScheduleResponse, fetchSchedule } from './useScheduleQuery';
import { first, includes, pick } from 'lodash';
import {
    UseQueryOptions,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { isEmpty } from 'helpers';
import { ApiResponse } from 'types';
import { Employee } from 'react-query-client/dto/ScheduleData';
import { AxiosError } from 'axios';
import outsideManagers from 'configs/outsideManagers';

type OfficeEmployeesResponse = ApiResponse<Employee[]>;
type OfficeEmployeesError = AxiosError<OfficeEmployeesResponse>;

type UseOfficeEmployeesQueryOptions = UseQueryOptions<
    ScheduleResponse,
    OfficeEmployeesError,
    OfficeEmployeesResponse
>;

const getQueryKey = (officeId: number) => {
    return ['employees', officeId];
};

const selectEmployees = (data: ScheduleResponse): OfficeEmployeesResponse => {
    return {
        ...data,
        data: first(data?.data.schedule)
            ?.employees?.filter(
                (employee) => !includes(outsideManagers, employee.id)
            )
            ?.map((item) => pick(item, ['id', 'name'])),
    } as OfficeEmployeesResponse;
};

export const useFetchOfficeEmployees = () => {
    const queryClient = useQueryClient();
    return useCallback(
        async (officeId: number) => {
            return await queryClient
                .fetchQuery<
                    ScheduleResponse,
                    OfficeEmployeesError,
                    ScheduleResponse
                >({
                    queryKey: getQueryKey(officeId),
                    queryFn: () => fetchSchedule(officeId),
                })
                .then((data) => selectEmployees(data));
        },
        [queryClient]
    );
};

const useOfficeEmployeesQuery = (
    officeId?: number,
    options?: UseOfficeEmployeesQueryOptions
) => {
    return useQuery<
        ScheduleResponse,
        OfficeEmployeesError,
        OfficeEmployeesResponse
    >({
        queryKey: ['employees', { officeId }],
        queryFn: () => fetchSchedule(officeId!),
        select: useCallback(selectEmployees, []),
        enabled: !isEmpty(officeId),
        ...options,
    });
};

export default useOfficeEmployeesQuery;
