import { Outlet } from 'react-router-dom';
import { Layout, HeaderLayout, ContentLayout, FooterLayout } from '../common';
import OfferFooter from './OfferFooter';
import { Logo } from 'components';
// import Promotion from './Promotion';
import { BANK_URL } from 'configs/urls';
import { OfferState } from 'types/Offer';
import { Fragment } from 'react';
import { useOfferQuery } from 'views/common';
import { AgreeButton } from 'views/Offer';

const OfferLayout = () => {
    const { data: offer } = useOfferQuery();
    return (
        <Layout>
            <HeaderLayout
                logo={<Logo logo="bank-logo.svg?1" rootPath={BANK_URL} />}
            >
                {offer?.data.state !== OfferState.DECLINED && (
                    <Fragment>
                        {/* <Promotion /> */}
                        <AgreeButton />
                    </Fragment>
                )}
            </HeaderLayout>
            <ContentLayout>
                <Outlet />
            </ContentLayout>
            <FooterLayout>
                <OfferFooter />
            </FooterLayout>
        </Layout>
    );
};

export default OfferLayout;
