import { InputBase, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { getClientName } from 'helpers/format';
import { map } from 'lodash';
import { useMemo } from 'react';
import { Client } from 'react-query-client/dto';
import { Data } from 'types';

export type MultipleProps = Data<Client[]> & {
    selected?: Client;
    onSelect: (id: string) => void;
};

const Multiple: React.FC<MultipleProps> = ({ data, selected, onSelect }) => {
    const items = useMemo(
        () =>
            map(data, (client) => (
                <MenuItem key={client.id} value={client.id}>
                    {getClientName(client)}
                </MenuItem>
            )),
        [data]
    );

    const handleChange = (event: SelectChangeEvent) => {
        onSelect(event.target.value as string);
        window.scrollTo(0, 0);
    };

    return (
        <Select
            value={selected?.id || ''}
            onChange={handleChange}
            input={<InputBase />}
        >
            {items}
        </Select>
    );
};

export default Multiple;
