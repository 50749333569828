import { ErrorSnackbarAction } from 'components';
import { useSnackbar } from 'notistack';
import { createElement } from 'react';
import { useCallback } from 'react';

const useMessage = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const showTechError = useCallback(
        (message: string, details?: any) => {
            enqueueSnackbar(message, {
                variant: 'error',
                autoHideDuration: 7000,
                action: (key) =>
                    createElement(ErrorSnackbarAction, {
                        details,
                        onClose: () => closeSnackbar(key),
                    }),
            });
        },
        [enqueueSnackbar, closeSnackbar]
    );

    const showError = useCallback(
        (message: string) => {
            enqueueSnackbar(message, {
                variant: 'error',
                autoHideDuration: 7000,
            });
        },
        [enqueueSnackbar]
    );

    const showSuccess = useCallback(
        (message: string) => {
            enqueueSnackbar(message, {
                variant: 'success',
            });
        },
        [enqueueSnackbar]
    );

    const showInfo = useCallback(
        (message: string) => {
            enqueueSnackbar(message, {
                variant: 'info',
            });
        },
        [enqueueSnackbar]
    );

    const showWarning = useCallback(
        (message: string) => {
            enqueueSnackbar(message, {
                variant: 'warning',
            });
        },
        [enqueueSnackbar]
    );

    return {
        showTechError,
        showError,
        showSuccess,
        showInfo,
        showWarning,
    };
};

export default useMessage;
