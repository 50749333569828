import React, { ReactNode } from 'react';
import Role from './Role';
import useRole from './useRole';

type RoleRendererProps = {
    role: Role;
    children: ReactNode;
};

const RoleRenderer: React.FC<RoleRendererProps> = ({ role, children }) => {
    const currentUserRole = useRole();
    return role === currentUserRole ? <>{children}</> : null;
};

export default RoleRenderer;
