import { isStatus } from 'helpers/api';
import {
    useCurrentClientId,
    useLogout,
    useOfferContext,
    usePrevious,
} from 'hooks';
import { useRegChangeClient, useRegVisit } from 'metrika';
import { useEffect, useLayoutEffect } from 'react';
import { api } from 'react-query-client';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { loggedInSelector } from 'state/loggedIn';
import { useOverviewQuery } from 'views/common';

const useUserPage = () => {
    const loggedIn = useRecoilValue(loggedInSelector);
    const prevLoggedIn = usePrevious(loggedIn);
    const location = useLocation();
    const logout = useLogout();
    const currentClientId = useCurrentClientId();
    const navigate = useNavigate();
    const offerContext = useOfferContext();

    useRegVisit();
    useRegChangeClient();

    const { isInitialLoading: initializing, isFetched: initialized } =
        useOverviewQuery();

    useLayoutEffect(() => {
        api.interceptors.response.use(
            (response) => response,
            async (error) => {
                if (isStatus(error, 401, 403)) {
                    logout();
                    navigate('/login', {
                        replace: true,
                    });
                }
                return Promise.reject(error);
            }
        );
    }, [logout, navigate]);

    useEffect(() => {
        if (!loggedIn) {
            const state = !prevLoggedIn ? { from: location } : undefined;
            navigate(`/login${location.search}`, {
                replace: true,
                state,
            });
        } else if (initialized) {
            if (offerContext?.hasOfferContext) {
                navigate(`/init-offer/${offerContext?.accessId}`);
            } else if (location.pathname === '/') {
                if (currentClientId) {
                    navigate(`/clients/${currentClientId}`, {
                        replace: !prevLoggedIn,
                    });
                } else {
                    navigate('/add-new-client', { replace: !prevLoggedIn });
                }
            }
        }
    }, [
        initialized,
        loggedIn,
        prevLoggedIn,
        currentClientId,
        location,
        offerContext,
        navigate,
    ]);

    return {
        loggedIn,
        initializing,
        initialized,
    };
};

export default useUserPage;
