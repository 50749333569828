import { Typography, TypographyProps } from '@mui/material';
import { getCurrencyConfig } from 'helpers/config';
import React from 'react';
import { NumericFormat } from 'react-number-format';

export enum AccountingDirection {
    CT = 'CT',
    DT = 'DT',
}

const { CT, DT } = AccountingDirection;

export type AmountProps = TypographyProps & {
    /**
     * Amount value.
     */
    value?: number;

    /**
     * Count of digits after dot.
     */
    decimalScale?: number;

    /**
     * Currency code (like `RUB`, `USD`, etc.).
     */
    currency?: string;

    /**
     * Operation direction.
     */
    direction?: AccountingDirection;

    /**
     * Element type.
     */
    component?: React.ElementType;
};

const Amount = React.forwardRef(
    (
        {
            value,
            currency = 'RUB',
            decimalScale = 2,
            direction,
            component = 'span',
            ...rest
        }: AmountProps,
        ref
    ) => {
        const formattedValue = value ? Math.abs(value) : value;

        let prefix = '',
            suffix = '';

        if (value !== undefined && value !== null) {
            if (value < 0 || direction === DT) {
                prefix = '− ';
            } else if (direction === CT) {
                prefix = '+ ';
            }
        }

        if (currency) {
            const { symbol } = getCurrencyConfig(currency);
            suffix = currency ? ` ${symbol}` : '';
        }

        return (
            <NumericFormat
                displayType="text"
                thousandSeparator=" "
                decimalScale={decimalScale}
                fixedDecimalScale={true}
                prefix={prefix}
                suffix={suffix}
                value={formattedValue}
                renderText={(formattedValue) => (
                    <Typography
                        {...rest}
                        ref={ref}
                        component={component}
                        sx={{
                            whiteSpace: 'pre',
                            ...rest.sx,
                        }}
                    >
                        {formattedValue}
                    </Typography>
                )}
            />
        );
    }
);

export default Amount;
