import { Chip, ChipProps } from '@mui/material';
import { lighten } from '@mui/system/colorManipulator';

export type ColoredChipProps = Omit<ChipProps, 'color'> & {
    color: string;
};

const ColoredChip: React.FC<ColoredChipProps> = ({ color, ...rest }) => (
    <Chip
        {...rest}
        sx={{
            color,
            backgroundColor: lighten(color, 0.95),
            borderColor: lighten(color, 0.5),
            ...rest.sx,
        }}
    />
);

export default ColoredChip;
