import { OfferPreview } from 'types/Offer';
import Account from './Account';

export enum ClientState {
    NEW = 'NEW',
    ACTIVE = 'ACTIVE',
    PREORDER = 'PREORDER',
}

type Client = {
    id: string;
    createdAt: string;
    name: string;
    taxId: string;
    isPrimary: boolean;
    state: ClientState;
    proposals: OfferPreview[] | null;
    accounts: Account[];
    // @calculated
    hasOpenedAccount: boolean;
};

export default Client;
