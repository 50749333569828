import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactNode } from 'react';
import CancelButton from './CancelButton';

export type ConfirmProps = {
    title: string;
    content: ReactNode;
    show: boolean;
    disagreeText?: string;
    agreeText?: string;
    onDisagree: () => void;
    onAgree: () => void;
    onClose: () => void;
};

const Confirm: React.FC<ConfirmProps> = ({
    title,
    content,
    show,
    disagreeText,
    agreeText,
    onDisagree,
    onAgree,
    onClose,
}) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Dialog
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth="sm"
            open={show}
            onClose={onClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onAgree} variant="contained">
                    {agreeText}
                </Button>
                <CancelButton onClick={onDisagree}>{disagreeText}</CancelButton>
            </DialogActions>
        </Dialog>
    );
};

Confirm.defaultProps = {
    disagreeText: 'Нет',
    agreeText: 'Да',
};

export default Confirm;
