import { Skeleton, Typography, Stack } from '@mui/material';

const PageSkeleton = () => (
    <Stack>
        <Typography variant="h2" marginBottom="30px">
            <Skeleton sx={{ width: { sm: '100%', md: '50%' } }} />
        </Typography>
        <Typography variant="h4">
            <Skeleton />
        </Typography>
        <Typography variant="h4">
            <Skeleton />
        </Typography>
        <Typography variant="h4">
            <Skeleton />
        </Typography>
    </Stack>
);

export default PageSkeleton;
