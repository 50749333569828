import {
    Avatar,
    Box,
    ListItemAvatar,
    ListItemButton,
    ListItemButtonProps,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material';
import { deepOrange, green, grey, pink, purple } from '@mui/material/colors';
import { FromNow } from 'components';
import { cut } from 'helpers/format';
import { sample } from 'lodash';
import { useMemo } from 'react';
import { Data, Notification } from 'types';

const MAX_LENGTH = 60;

type NotificationItemProps = ListItemButtonProps & Data<Notification>;

const NotificationItem: React.FC<NotificationItemProps> = ({
    data,
    ...listItemProps
}) => {
    const { title, isNew, type, createdAt } = data!;
    const avaBgcolor = useMemo(
        () =>
            sample([
                deepOrange[500],
                purple[500],
                grey[500],
                pink[200],
                green[200],
            ]),
        []
    );
    return (
        <ListItemButton
            sx={{
                paddingLeft: '24px',
                paddingRight: '24px',
                backgroundColor: isNew ? 'rgba(0, 0, 0, 0.025)' : 'none',
            }}
            {...listItemProps}
        >
            <ListItemIcon
                sx={{
                    padding: 0,
                    marginRight: '18px',
                    minWidth: '10px',
                }}
            >
                <Box
                    sx={{
                        width: '10px',
                        height: '10px',
                        backgroundColor: isNew
                            ? 'secondary.main'
                            : 'text.disabled',
                        borderRadius: '10px',
                    }}
                />
            </ListItemIcon>
            <ListItemAvatar>
                <Avatar sx={{ bgcolor: avaBgcolor }}>{type}</Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <>
                        <Typography
                            component="span"
                            variant="body2"
                            fontWeight={isNew ? 500 : 400}
                            {...(title.length > MAX_LENGTH && { title })}
                        >
                            {cut(title, MAX_LENGTH)}
                        </Typography>
                        <FromNow date={createdAt} />
                    </>
                }
            />
        </ListItemButton>
    );
};

export default NotificationItem;
