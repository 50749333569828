import { TabProps, Tabs, TabsProps } from '@mui/material';
import React, { ReactElement, useMemo } from 'react';
import { useLocation, useResolvedPath } from 'react-router-dom';
import { Link } from 'react-router-dom';

const RouterTabs: React.FC<TabsProps> = ({ children, ...rest }) => {
    const location = useLocation();
    const { pathname: absolutePathname } = useResolvedPath('');

    const linkTabs = useMemo(() => {
        return React.Children.map(children, (child) => {
            const tab = child as ReactElement<TabProps>;
            return React.cloneElement(tab, {
                // @ts-ignore
                component: Link,
                // @ts-ignore
                to: tab.props.value,
            });
        });
    }, [children]);

    const activeTab = useMemo(() => {
        if (!linkTabs) return null;

        for (let item of linkTabs) {
            const tab = item as ReactElement<TabProps>;
            // @ts-ignore
            const { to } = tab.props;
            let locationPathname = location.pathname;
            let toPathname = `${absolutePathname}/${to}`;
            if (locationPathname === toPathname) {
                return tab.props.value;
            }
        }
        // default first tab
        return linkTabs[0].props.value;
    }, [absolutePathname, location.pathname, linkTabs]);

    return (
        <Tabs value={activeTab} {...rest}>
            {linkTabs}
        </Tabs>
    );
};

export default RouterTabs;
