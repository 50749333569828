import { Link } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Sign } from 'types';
import { ApiResponse } from 'types';
import { isEmpty } from 'helpers';

type SendCodeButtonProps = {
    sendCode: () => Promise<ApiResponse<Sign>>;
};

const disabledStyles = {
    color: 'action.disabled',
    pointerEvents: 'none',
};

const SendCodeButton: React.FC<SendCodeButtonProps> = ({ sendCode }) => {
    const [sending, setSending] = useState(false);
    const [time, setTime] = useState(0);
    const timerRef = useRef<NodeJS.Timeout>();

    const stop = useCallback(() => {
        if (!isEmpty(timerRef.current)) {
            clearInterval(timerRef.current);
        }
    }, []);

    const start = useCallback(async () => {
        try {
            setSending(true);
            const result = await sendCode();
            setSending(false);
            const initialTime = result.data.token.nextIn;
            setTime(initialTime);
            timerRef.current = setInterval(() => {
                setTime((prevTime) => --prevTime);
            }, 1000);
        } catch (error) {
            setSending(false);
        }
    }, [sendCode]);

    useEffect(() => {
        if (time < 1) {
            stop();
        }
    }, [time, stop]);

    useEffect(() => {
        start();
        return () => {
            stop();
        };
    }, [start, stop]);

    return time > 0 ? (
        <>Запросить код повторно можно через {time} секунд</>
    ) : (
        <Link onClick={start} sx={sending ? disabledStyles : undefined}>
            {sending ? 'Высылаем код...' : 'Выслать код повторно'}
        </Link>
    );
};

export default SendCodeButton;
