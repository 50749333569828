import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ArrowLeft = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 476.213 476.213">
            <polygon points="476.213,223.107 76.212,223.107 76.212,161.893 0,238.108 76.212,314.32 76.212,253.107 476.213,253.107 " />
        </SvgIcon>
    );
};

export default ArrowLeft;
