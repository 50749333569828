import { Box, Stack, StackProps, Typography } from '@mui/material';
import { Href, Mailto } from 'components';
import {
    ASV_URL,
    BANK_URL,
    FINANCIAL_INDICATORS,
    INTERNET_BANKING_URL,
    PSCB_PLUS_EMAIL,
    VALUABLE_PAPERS_INFO_URL,
} from 'configs/urls';

const DefaultFooter: React.FC<StackProps> = (props) => (
    <Stack
        spacing={5}
        direction={{
            xs: 'column',
            md: 'row',
        }}
        {...props}
    >
        <Stack
            width={{
                xs: '100%',
                md: 'auto',
            }}
            direction={{
                xs: 'row',
                md: 'column',
            }}
            spacing={{
                xs: 2,
                md: 1,
            }}
            flexGrow={1}
        >
            <Href href={INTERNET_BANKING_URL} color="inherit">
                ДБО Банка
            </Href>
            <Mailto
                email={PSCB_PLUS_EMAIL}
                subject="Перезвоните мне"
                body="Пожалуйста, перезвоните мне по телефону: (укажите здесь ваш номер телефона) в ближайшее время, мне нужна помощь по ПСКБ +"
                color="inherit"
            >
                Позвоните мне
            </Mailto>
            <Mailto email={PSCB_PLUS_EMAIL} color="inherit">
                Написать нам
            </Mailto>
        </Stack>
        <Stack
            spacing={1}
            textAlign={{
                xs: 'left',
                md: 'right',
            }}
        >
            <Href href={BANK_URL} variant="caption" color="inherit">
                Акционерное общество «Петербургский социальный коммерческий
                банк»
            </Href>
            <Typography variant="caption">
                Генеральная лицензия ЦБ РФ №2551 от 12 февраля 2015 г.
            </Typography>
            <Href href={FINANCIAL_INDICATORS} variant="caption" color="inherit">
                Информация о максимальных процентных ставках по вкладам
                физических лиц
            </Href>
            <Href
                href={VALUABLE_PAPERS_INFO_URL}
                variant="caption"
                color="inherit"
            >
                Раскрытие информации профучастника рынка ценных бумаг
            </Href>
        </Stack>
        <Href href={ASV_URL}>
            <Box
                component="img"
                src="/images/asv.png?2"
                width="70"
                height="70"
                alt="Все вклады застрахованы"
                sx={{
                    filter: 'drop-shadow(0px 1px 1px #ccc)',
                }}
            />
        </Href>
    </Stack>
);

export default DefaultFooter;
