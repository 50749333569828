import { Box, Table as MuiTable } from '@mui/material';
import Body from './Body';
import Head from './Head';
import TableContainer from './TableContainer';
import TableProps from './TableProps';
import stickySx from './stickySx';
import ErrorAlert from 'components/ErrorAlert';

const Table = function <TData>(props: TableProps<TData>) {
    const {
        showHeader = true,
        bordered,
        sticky,
        toolbar,
        footer,
        error,
    } = props;
    const tableSx = sticky ? stickySx : undefined;
    if (error) {
        return <ErrorAlert error={error} />;
    }
    return (
        <TableContainer bordered={bordered}>
            {toolbar && (
                <Box p={bordered ? '20px 25px 10px 25px' : 0}>
                    {toolbar(props)}
                </Box>
            )}
            <MuiTable sx={tableSx}>
                {showHeader && <Head<TData> {...props} />}
                <Body<TData> {...props} />
                {footer && footer(props)}
            </MuiTable>
        </TableContainer>
    );
};

export default Table;
