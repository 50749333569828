import { Avatar, Stack, Typography } from '@mui/material';
import { Mailto, Phone } from 'components';
import { insertIf, isEmpty } from 'helpers';
import { map, size } from 'lodash';
import { Fragment, useMemo } from 'react';
import { Data, Manager as ManagerType } from 'types';

export const Contacts: React.FC<Data<ManagerType>> = ({ data }) => {
    const { email, cellPhone, workPhone } = data || {};
    const contacts = useMemo(
        () => [
            ...insertIf(<Mailto email={email} />, () => !isEmpty(email)),
            ...insertIf(<Phone>{cellPhone}</Phone>),
            ...insertIf(workPhone),
        ],
        [email, cellPhone, workPhone]
    );

    const count = size(contacts);
    const list = map(contacts, (item, index) => (
        <Fragment key={index}>
            {item}
            {index + 1 < count && ', '}
        </Fragment>
    ));

    return <span>{list}</span>;
};

const Manager: React.FC<Data<ManagerType>> = ({ data }) => (
    <Stack direction="row" spacing={3} alignItems="center">
        <Avatar
            src={data?.photoUrl}
            sx={{ width: 80, height: 80 }}
            imgProps={{
                style: {
                    objectPosition: '0px -5px',
                },
            }}
        />
        <Stack>
            <Typography variant="subtitle2">{data?.name}</Typography>
            <Contacts data={data} />
        </Stack>
    </Stack>
);

export default Manager;
