import { Stack } from '@mui/material';
import { KeyboardEvent, ReactNode } from 'react';
import { Form } from 'react-final-form';
import { Loadable } from 'types';
import FilterContext from './FilterContext';

type ToolbarProps<TData> = {
    mainFilter?: ReactNode;
    extendedFilter?: ReactNode;
    actions?: ReactNode;
    loadableProps: Loadable<TData>;
};

const Toolbar = function <TData>({
    mainFilter,
    extendedFilter,
    actions,
    loadableProps: { variables, onFilter },
}: ToolbarProps<TData>) {
    const filter = variables?.filter || {};
    return (
        <Form
            initialValues={filter}
            onSubmit={(values) => {
                onFilter?.(values);
            }}
        >
            {({ handleSubmit, form, valid, dirty }) => {
                const submit = () => {
                    if (valid && dirty) {
                        form.submit();
                    }
                };

                const handleKeyPress = (
                    event: KeyboardEvent<HTMLFormElement>
                ) => {
                    if (event.key === 'Enter') {
                        event.preventDefault();
                        submit();
                    }
                };

                return (
                    <FilterContext.Provider value={submit}>
                        <Stack
                            component="form"
                            mb="20px"
                            direction="column"
                            spacing={2}
                            onSubmit={handleSubmit}
                            onKeyPress={handleKeyPress}
                        >
                            <Stack
                                direction="row"
                                spacing={5}
                                justifyContent="space-between"
                            >
                                {mainFilter}
                                {actions}
                            </Stack>
                            {extendedFilter}
                        </Stack>
                    </FilterContext.Provider>
                );
            }}
        </Form>
    );
};

export default Toolbar;
