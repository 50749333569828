import { DoneAllOutlined } from '@mui/icons-material';
import {
    Button,
    DialogActions,
    IconButton,
    List,
    Popover,
    PopoverProps,
    Tooltip,
    Typography,
    Stack,
} from '@mui/material';
import { isEmpty, map } from 'lodash';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Data, Notification } from 'types';
import NotificationItem from './NotificationItem';

type NotificationsPopoverProps = Data<Notification[]> &
    PopoverProps & {
        onHide: () => void;
    };

const NotificationsPopover: React.FC<NotificationsPopoverProps> = ({
    data,
    onHide,
    ...popoverProps
}) => {
    const items = useMemo(
        () =>
            map(data, (item) => (
                <NotificationItem key={item.id} data={item} onClick={onHide} />
            )),
        [data, onHide]
    );
    return (
        <Popover
            {...popoverProps}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            PaperProps={{
                sx: {
                    maxWidth: '400px',
                },
            }}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                padding="10px 24px"
                spacing={2}
                sx={{
                    borderBottom: '1px dashed #919eab3d',
                }}
            >
                <Typography fontSize="18px" fontWeight={400}>
                    Оповещения
                </Typography>
                {!isEmpty(data) && (
                    <Tooltip
                        title="Отметить все как прочитанные"
                        enterDelay={1500}
                    >
                        <IconButton color="primary">
                            <DoneAllOutlined />
                        </IconButton>
                    </Tooltip>
                )}
            </Stack>
            <List
                sx={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    '> div:not(:last-child)': {
                        borderBottom: '1px solid #e9e9e9',
                    },
                }}
            >
                {items}
            </List>
            {isEmpty(data) && (
                <Typography
                    component="div"
                    variant="caption"
                    color="text.secondary"
                    textAlign="center"
                    padding="10px"
                >
                    ничего нет
                </Typography>
            )}
            {!isEmpty(data) && (
                <DialogActions
                    sx={{
                        justifyContent: 'center',
                        borderTop: '1px dashed #919eab3d',
                    }}
                >
                    <Button
                        variant="text"
                        size="small"
                        component={Link}
                        color="info"
                        to="/notifications"
                        fullWidth
                        onClick={onHide}
                    >
                        Посмотреть все
                    </Button>
                </DialogActions>
            )}
        </Popover>
    );
};

export default NotificationsPopover;
