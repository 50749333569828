import { Data } from 'types';
import { RouterLink } from 'components';
import { Client } from 'react-query-client/dto';
import { getClientName } from 'helpers/format';

const Single: React.FC<Data<Client>> = ({ data }) => {
    return (
        <RouterLink to={`/clients/${data?.id}`}>
            {getClientName(data)}
        </RouterLink>
    );
};

export default Single;
