import { useQueryClient } from '@tanstack/react-query';
import { useResetRecoilState } from 'recoil';
import { tokenState } from 'state/loggedIn';

const useLogout = () => {
    const resetToken = useResetRecoilState(tokenState);
    const queryClient = useQueryClient();

    return () => {
        resetToken();
        queryClient.clear();
    };
};

export default useLogout;
