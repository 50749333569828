import { Link, LinkProps } from '@mui/material';
import React from 'react';

const Href = React.forwardRef<any, LinkProps>(
    ({ children, href, target = '_blank', ...rest }, ref) => (
        <Link
            ref={ref}
            href={href}
            target={target}
            rel="noopener noreferrer"
            {...rest}
        >
            {children || href}
        </Link>
    )
);

export default Href;
