import { TableContainer as MuiTableContainer, Paper } from '@mui/material';
import { ReactNode } from 'react';

type TableContainerProps = {
    bordered?: boolean;
    children: ReactNode;
};

const TableContainer: React.FC<TableContainerProps> = ({
    bordered = false,
    children,
}) => {
    return (
        <MuiTableContainer
            component={Paper}
            variant={bordered ? 'outlined' : 'elevation'}
            elevation={bordered ? undefined : 0}
            square
            sx={{
                minHeight: '200px',
                overflow: 'unset', // for thead > th sticky
            }}
        >
            {children}
        </MuiTableContainer>
    );
};

export default TableContainer;
