import { Box } from '@mui/system';
import { ReactNode, useMemo } from 'react';
import { isSafari } from 'react-device-detect';

// 160px = 80px * 2, 80px = page area padding
// Safari bug: incorrect calculation of the width of a table cell element
// whose position property is absolute
const getContainerWidth = (): string => {
    const element = document.querySelector('main');
    const styles = window.getComputedStyle(element as Element);
    const result = isSafari
        ? `calc(100% - calc((${styles.marginLeft} + ${styles.paddingLeft}) * 2))`
        : '100%';
    return result;
};

const LoadingContainer: React.FC<{ children?: ReactNode }> = ({ children }) => {
    const containerWidth = useMemo(() => {
        return getContainerWidth();
    }, []);

    return (
        <Box
            component="tr"
            sx={{
                position: 'relative',
            }}
        >
            <Box
                component="td"
                sx={{
                    position: 'absolute',
                    display: 'block',
                    width: containerWidth,
                    height: '4px',
                    padding: 0,
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

export default LoadingContainer;
