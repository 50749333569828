import { TableCell } from '@mui/material';
import { get } from 'lodash';
import TableColumn from './TableColumn';
import TableCellDataProps from './TableCellDataProps';
import TableCellRenderProps from './TableCellRenderProps';
import { useCallback, useMemo } from 'react';

type CellProps<TData> = {
    column: TableColumn<TData>;
    record: TData;
    onClick?: (record: TData) => void;
    onExpand?: () => void;
};

const Cell = function <TData>({
    column,
    record,
    onClick,
    onExpand,
}: CellProps<TData>) {
    const cellContent = useMemo(() => {
        const value = get(record, column.field!);
        const dataProps: TableCellDataProps<TData> = { value, record };

        const formattedValue = column.valueFormatter
            ? column.valueFormatter(dataProps)
            : value;

        if (column.renderCell) {
            const renderProps: TableCellRenderProps<TData> = {
                value,
                record,
                formattedValue,
                onExpand,
            };
            return column.renderCell!(renderProps);
        } else {
            return formattedValue;
        }
    }, [column, record, onExpand]);

    const handleCellClick = useCallback(() => {
        if (onClick && !column.clickable) {
            onClick(record);
        }
    }, [column, record, onClick]);

    return (
        <TableCell align={column.align} onClick={handleCellClick}>
            {cellContent}
        </TableCell>
    );
};

export default Cell;
