import { useState } from 'react';

const useDialog = () => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleAccept = () => {
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return {
        open,
        onOpen: handleOpen,
        onAccept: handleAccept,
        onClose: handleClose,
    };
};

export default useDialog;
