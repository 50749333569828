import { ContractDirectionType } from 'types';

export enum ContractDirection {
    IMPORT_GOODS = 'IMPORT_GOODS',
    EXPORT_GOODS = 'EXPORT_GOODS',
    IMPORT_SERVICES = 'IMPORT_SERVICES',
    EXPORT_SERVICES = 'EXPORT_SERVICES',
    AMBIGUOUS = 'AMBIGUOUS',
}

const contractDirections: { [key: string]: ContractDirectionType } = {
    [ContractDirection.IMPORT_GOODS]: {
        code: ContractDirection.IMPORT_GOODS,
        title: 'Импорт товаров',
        symbol: '←',
    },
    [ContractDirection.EXPORT_GOODS]: {
        code: ContractDirection.EXPORT_GOODS,
        title: 'Экспорт товаров',
        symbol: '→',
    },
    [ContractDirection.IMPORT_SERVICES]: {
        code: ContractDirection.IMPORT_SERVICES,
        title: 'Импорт услуг',
        symbol: '←',
    },
    [ContractDirection.EXPORT_SERVICES]: {
        code: ContractDirection.EXPORT_SERVICES,
        title: 'Экспорт услуг',
        symbol: '→',
    },
    [ContractDirection.AMBIGUOUS]: {
        code: ContractDirection.AMBIGUOUS,
        title: 'Импорт/Экспорт',
        symbol: '∞',
    },
    UNDEFINED: {
        code: '',
        title: '',
        symbol: '',
    },
};

export default contractDirections;
