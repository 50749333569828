import { isEmpty } from 'helpers';
import Amount, { AmountProps } from './Amount';
import { Typography } from '@mui/material';

const Price: React.FC<AmountProps> = (props) => {
    if (isEmpty(props.value)) return null;

    return props.value! > 0 ? (
        <Amount decimalScale={0} {...props} />
    ) : (
        <Typography component="span" color="text.secondary" {...props}>
            бесплатно
        </Typography>
    );
};

export default Price;
