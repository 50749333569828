import { isEmpty } from 'helpers';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const useOfferContext = () => {
    const [searchParams] = useSearchParams();
    const accessId = searchParams.get('accessId');

    return useMemo(
        () => ({
            hasOfferContext: !isEmpty(accessId),
            accessId,
        }),
        [accessId]
    );
};

export default useOfferContext;
