import { AccessTimeOutlined } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';

type FromNowProps = {
    /**
     * Event date (ISO string).
     */
    date: string;
};
const FromNow: React.FC<FromNowProps> = ({ date }) => {
    const fromNow = dayjs(date).fromNow();
    return (
        <Stack
            direction="row"
            spacing={'5px'}
            alignItems="center"
            marginTop="3px"
        >
            <AccessTimeOutlined sx={{ fontSize: '14px' }} />
            <Typography
                component="span"
                variant="caption"
                color="text.secondary"
            >
                {fromNow}
            </Typography>
        </Stack>
    );
};

export default FromNow;
