import { Tooltip, TooltipProps } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

type PopupProps = Omit<TooltipProps, 'title'> & {
    content: TooltipProps['title'];
};

const Popup = styled(({ className, content, ...props }: PopupProps) => (
    <Tooltip
        arrow={false}
        placement="top"
        title={content}
        classes={{ popper: className }}
        {...props}
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${grey[300]}`,
        borderRadius: '8px',
        maxWidth: '500px',
        boxShadow: theme.shadows[2],
    },
}));

export default Popup;
