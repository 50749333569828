import { ReactNode } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IconProps, Tooltip, TooltipProps } from '@mui/material';

type InfoProps = {
    /**
     * Information content.
     */
    children: ReactNode;
    /**
     * Info icon size.
     */
    iconSize?: IconProps['fontSize'];
    /**
     * Info icon color.
     */
    iconColor?: string;
    /**
     * Tooltip placement.
     * @default 'top'
     */
    placement?: TooltipProps['placement'];
};

const Info: React.FC<InfoProps> = ({
    children,
    iconSize,
    iconColor,
    placement = 'top',
}) => {
    return (
        <Tooltip title={children!} enterDelay={0} placement={placement}>
            <InfoOutlinedIcon
                fontSize={iconSize}
                sx={{
                    cursor: 'pointer',
                    color: iconColor,
                }}
            />
        </Tooltip>
    );
};

export default Info;
