import { NotificationsNone } from '@mui/icons-material';
import { Badge, IconButton } from '@mui/material';
import { Fragment } from 'react';
import NotificationsPopover from './NotificationsPopover';

type NotificationsProps = {
    newCount?: number;
};

const Notifications: React.FC<NotificationsProps> = ({ newCount = 0 }) => {
    return (
        <Fragment>
            <IconButton>
                <Badge color="secondary" badgeContent={newCount} max={99}>
                    <NotificationsNone />
                </Badge>
            </IconButton>
            <NotificationsPopover
                open={false}
                data={/*notificationsMock*/ undefined}
                onHide={() => {}}
            />
        </Fragment>
    );
};

export default Notifications;
