import { WOOT_USER_ID, NOT_REQUIRED_MANAGER_ID } from 'configs/ids';
import { includes } from 'lodash';
import { ReactElement } from 'react';
import { Data, Manager } from 'types';

const EXCLUDED_IDS = [WOOT_USER_ID, NOT_REQUIRED_MANAGER_ID];

type RenderManagerProps = Data<Manager> & {
    children: (renderProps: Data<Manager>) => ReactElement;
};

const RenderManager: React.FC<RenderManagerProps> = ({ data, children }) => {
    if (includes(EXCLUDED_IDS, data?.id)) return null;
    return children({ data });
};

export default RenderManager;
