import { useParams } from 'react-router-dom';
import produce from 'immer';
import {
    FetchQueryOptions,
    UseQueryOptions,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { ApiResponse, Offer } from 'types';
import { useCallback, useMemo } from 'react';
import { publicApi } from 'react-query-client';
import { getOfferManagerPhotoUrl } from 'helpers/url';
import { AxiosError } from 'axios';
import { isEmpty } from 'helpers';

// MOCK API
// import axios from 'axios';
// import MockAdapter from 'axios-mock-adapter';
// import { offerMock } from 'views/common';

// const api = axios.create();
// const mock = new MockAdapter(api, { delayResponse: 300 });

// // @ts-ignore
// mock.onGet(/\/cmp\/proposals\/(.+)$/).reply(({ url }) => {
//     const accessId = url?.split('/')[3];
//     console.log('on get offer', accessId);
//     return [200, { data: offerMock }];
// });
//

export type OfferResponse = ApiResponse<Offer>;

type OfferError = AxiosError<OfferResponse>;

type UseOfferQueryOptions = UseQueryOptions<
    OfferResponse,
    OfferError,
    OfferResponse
>;
type FetchOfferQueryOptions = FetchQueryOptions<
    OfferResponse,
    OfferError,
    OfferResponse
>;

export const getOfferQueryKey = (accessId: string) => {
    return ['offer', accessId];
};

export const useOfferQueryKey = () => {
    const { accessId } = useParams();
    return useMemo(() => getOfferQueryKey(accessId!), [accessId]);
};

const createSelect = (accessId: string) => (data: OfferResponse) => {
    return produce(data, (draft) => {
        draft.data.manager.photoUrl = getOfferManagerPhotoUrl(accessId);
    });
};

export const useInvalidateOffer = () => {
    const queryClient = useQueryClient();
    const queryKey = useOfferQueryKey();
    return useCallback(
        () =>
            queryClient.invalidateQueries({
                queryKey,
            }),
        [queryClient, queryKey]
    );
};

type OfferSetter = (data: OfferResponse) => OfferResponse;

export const useSetOfferData = () => {
    const queryClient = useQueryClient();
    const queryKey = useOfferQueryKey();
    return useCallback(
        (setter: OfferSetter) => {
            queryClient.setQueryData<OfferResponse>(queryKey, (oldData) => {
                if (!oldData) return oldData;
                return setter(oldData);
            });
        },
        [queryClient, queryKey]
    );
};

export const fetchOffer = async (accessId: string) =>
    (await publicApi.get(`/cmp/proposals/${accessId}`)).data;

export const useOfferQueryWithoutPageCtx = (
    accessId: string,
    options?: UseOfferQueryOptions
) => {
    const queryKey = getOfferQueryKey(accessId);
    return useQuery<OfferResponse, OfferError>({
        queryKey,
        queryFn: () => fetchOffer(accessId!),
        select: createSelect(accessId!),
        enabled: !isEmpty(accessId),
        ...options,
    });
};

export const useFetchOffer = () => {
    const { accessId } = useParams();
    const queryClient = useQueryClient();
    const queryKey = useOfferQueryKey();
    return useCallback(
        async (options?: FetchOfferQueryOptions) => {
            return await queryClient.fetchQuery<OfferResponse, OfferError>({
                queryKey,
                queryFn: () => fetchOffer(accessId!),
                ...options,
            });
        },
        [queryClient, queryKey, accessId]
    );
};

const useOfferQuery = (options?: UseOfferQueryOptions) => {
    const { accessId } = useParams();
    const queryKey = useOfferQueryKey();
    return useQuery<OfferResponse, OfferError>({
        queryKey,
        queryFn: () => fetchOffer(accessId!),
        select: createSelect(accessId!),
        ...options,
    });
};

export default useOfferQuery;
