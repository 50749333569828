import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import callApi from './callApi';

const useRegChangePage = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        callApi('hit', pathname);
    }, [pathname]);
};

export default useRegChangePage;
