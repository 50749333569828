import { grey } from '@mui/material/colors';
import ComponentsTheme from './ComponentsTheme';

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        default: true;
        dashed: true;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsSizeOverrides {
        giant: true;
    }
}

const MuiButton: ComponentsTheme['MuiButton'] = {
    variants: [
        {
            props: {
                variant: 'default',
            },
            style: {
                border: `1px solid ${grey[400]}`,
            },
        },
        {
            props: {
                variant: 'dashed',
            },
            style: {
                border: `1px dashed ${grey[500]}`,
            },
        },
        {
            props: {
                size: 'giant',
            },
            style: {
                padding: '1rem 1.8rem',
                fontSize: '1.4rem',
            },
        },
    ],
    defaultProps: {
        variant: 'contained',
        disableElevation: true,
    },
    styleOverrides: {
        outlinedPrimary: {
            backgroundColor: '#f7fef3',
        },
    },
};

export default MuiButton;
