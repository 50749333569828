import { AbilityBuilder, MongoAbility, createMongoAbility } from '@casl/ability';
import Role from './Role';
import {
    PabContract,
    PabContractStateType,
    PabOrder,
    PabOrderState,
} from 'views/pab/common/types';

export type Actions = 'read' | 'create' | 'clone' | 'recall' | 'refuse' | 'approve';
type Subjects = 'PabContract' | PabContract | 'PabOrder' | PabOrder;

export type AppAbility = MongoAbility<[Actions, Subjects]>;

export const createAbility = (role: Role) => {
    const { can, cannot, build } = new AbilityBuilder<AppAbility>(createMongoAbility);

    can('read', 'PabContract');

    if (role === Role.CLIENT) {
        cannot('read', 'PabContract', ['client']);
        can('create', 'PabContract');
        can('clone', 'PabContract', {
            'state.name': {
                $in: [
                    PabContractStateType.CREATED,
                    PabContractStateType.CONFIRMED,
                    PabContractStateType.AGENT_ACCEPTED,
                    PabContractStateType.ACTIVATED,
                ],
            },
        });
        can('recall', 'PabContract', {
            'state.name': PabContractStateType.CREATED,
        });
        can('create', 'PabOrder', {
            contractState: {
                $in: [PabContractStateType.ACTIVATED, undefined, null],
            },
        });
        can('clone', 'PabOrder');
    }

    if (role === Role.PAB) {
        cannot('read', 'PabContract', ['icon', 'agent', 'commodity']);
        can('approve', 'PabContract', {
            'state.name': PabContractStateType.CONFIRMED,
        });
        can('refuse', 'PabContract', {
            'state.name': PabContractStateType.CONFIRMED,
        });
        can('approve', 'PabOrder', {
            'state.name': PabOrderState.NEW,
        });
        can('refuse', 'PabOrder', {
            'state.name': PabOrderState.NEW,
        });
    }

    return build();
};
