import { Box, BoxProps, SxProps } from '@mui/material';

const keyframes: SxProps = {
    '@keyframes cloud-line': {
        from: {
            transform: 'translateX(0)',
        },
        to: {
            transform: `translateX(-100%)`,
        },
    },
};

type CloudProps = BoxProps & {
    src?: string;
    scale?: number;
};

const Cloud: React.FC<CloudProps> = ({ src, scale = 1, sx, ...rest }) => (
    <Box
        display="block"
        width="100px"
        height="30px"
        // border="2px solid #efefef"
        // bgcolor="#f8f8f8"
        // borderRadius="20px"
        position="absolute"
        sx={{
            backgroundImage: `url('/images/clouds/${src}')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPositionY: 'center',
            transform: `scale(${scale})`,
            filter: 'blur(1.8px)',
            opacity: 0.35,
            ...sx,
        }}
        {...rest}
    />
);

type CloudLineContentProps = BoxProps & {
    duration: number;
};

const CloudLineContent: React.FC<CloudLineContentProps> = ({
    duration,
    ...rest
}) => (
    <Box
        position="relative"
        // border="1px solid #00ff00"
        width="100vw"
        height="100%"
        {...rest}
        sx={{
            animation: `cloud-line ${duration}s linear infinite`,
            ...rest.sx,
        }}
    />
);

type CloudLineProps = BoxProps & {
    duration: number;
};

const CloudLine: React.FC<CloudLineProps> = ({
    duration,
    children,
    ...rest
}) => (
    <Box
        display="flex"
        // border="1px solid #ff0000"
        width="200vw"
        height="100%"
        position="absolute"
        {...rest}
    >
        <CloudLineContent duration={duration}>{children}</CloudLineContent>
        <CloudLineContent duration={duration}>{children}</CloudLineContent>
    </Box>
);

const Cloud1: React.FC<CloudProps> = (props) => (
    <Cloud src="cloud-1.svg" width="110.46px" height="59.78px" {...props} />
);

const Cloud2: React.FC<CloudProps> = (props) => (
    <Cloud src="cloud-2.svg" width="113.73px" height="45.17px" {...props} />
);

const Cloud3: React.FC<CloudProps> = (props) => (
    <Cloud src="cloud-3.svg" width="52.03px" height="28.53px" {...props} />
);

const Cloud4: React.FC<CloudProps> = (props) => (
    <Cloud src="cloud-4.svg" width="41.35px" height="19.48px" {...props} />
);

const Cloud5: React.FC<CloudProps> = (props) => (
    <Cloud src="cloud-5.svg" width="146.14px" height="46.07px" {...props} />
);

const Cloud6: React.FC<CloudProps> = (props) => (
    <Cloud src="cloud-6.svg" width="111.11px" height="37.35px" {...props} />
);

const CloudsFrame: React.FC<BoxProps> = ({ sx, ...rest }) => (
    <Box
        width="100%"
        height="200px"
        position="absolute"
        overflow="hidden"
        sx={{
            ...keyframes,
            ...sx,
        }}
        {...rest}
    >
        <CloudLine duration={280}>
            <Cloud1 top="0px" left="5%" />
            <Cloud3 top="10px" left="15%" />
        </CloudLine>
        <CloudLine duration={190}>
            <Cloud2 top="50px" left="20%" />
            <Cloud4 top="60px" left="29%" />
        </CloudLine>
        <CloudLine duration={195}>
            <Cloud5 top="5px" left="33%" scale={1.1} />
            <Cloud6 top="30px" left="47%" />
        </CloudLine>
        <CloudLine duration={200}>
            <Cloud4 top="30px" left="55%" scale={1.3} />
        </CloudLine>
        <CloudLine duration={210}>
            <Cloud1 top="0px" left="63%" scale={1.1} />
            <Cloud3 top="20px" left="72%" />
            <Cloud2 top="50px" left="71%" />
        </CloudLine>
        <CloudLine duration={270}>
            <Cloud6 top="30px" left="84%" scale={1.2} />
            <Cloud6 top="50px" left="90%" />
        </CloudLine>
    </Box>
);

export default CloudsFrame;
