import { useMatch } from 'react-router-dom';
import { YMInitializer } from 'react-yandex-metrika';

const YMMetrika = () => (
    <YMInitializer
        accounts={[88115107]}
        options={{
            clickmap: true,
            webvisor: true,
            trackLinks: true,
            accurateTrackBounce: true,
        }}
        version="2"
    />
);

const Metrika = () => {
    const isProxyLogin = useMatch('/proxy/:token');

    return process.env.NODE_ENV === 'production' && !isProxyLogin ? (
        <YMMetrika />
    ) : null;
};

export default Metrika;
