import { isEmpty, isThenable } from 'helpers';
import { isValidFileSize } from 'helpers/file';
import { filter } from 'lodash';
import { ReactElement, useCallback, useRef, useState } from 'react';

const inputStyle = {
    display: 'none',
};

export type FileSelectorRenderProps = {
    selectedFiles?: File[] | File;
    invalidFiles?: File[];
    uploading: boolean;
    onBrowse: () => void;
};

export type FileSelectorProps = {
    multiple?: boolean;
    accept?: string;
    maxFileSize?: number;
    onSelect?: (files: FileList) => any | PromiseLike<any>;
    children: (renderProps: FileSelectorRenderProps) => ReactElement;
};

const FileSelector: React.FC<FileSelectorProps> = ({
    multiple = true,
    accept,
    maxFileSize = 50,
    onSelect,
    children,
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [selectedFiles, setSelectedFiles] = useState<File[] | File>();
    const [invalidFiles, setInvalidFiles] = useState<File[]>();
    const [uploading, setUploading] = useState(false);

    const handleChangeFile = useCallback(() => {
        const inputEl = inputRef.current;
        const files = inputEl?.files;
        const invalid = filter(
            files,
            (file) => !isValidFileSize(maxFileSize, file.size)
        );
        if (isEmpty(invalid) && files) {
            setSelectedFiles(multiple ? Array.from(files) : files[0]);
            const selectReult = onSelect?.(files!);
            if (isThenable(selectReult)) {
                setUploading(true);
                selectReult.finally(() => {
                    setUploading(false);
                });
            }
        }
        setInvalidFiles(invalid);
    }, [maxFileSize, multiple, onSelect]);

    const onBrowse = useCallback(() => {
        const inputEl = inputRef.current;
        if (inputEl?.value) {
            inputEl.value = '';
        }
        inputEl?.click();
    }, []);

    const renderer = children({
        selectedFiles,
        invalidFiles,
        uploading,
        onBrowse,
    });

    return (
        <>
            <input
                ref={inputRef}
                type="file"
                multiple={multiple}
                accept={accept}
                style={inputStyle}
                onChange={handleChangeFile}
            />
            {renderer}
        </>
    );
};

export default FileSelector;
