import { Stack, StackProps } from '@mui/material';
import React from 'react';

export const FormFields: React.FC<StackProps> = (props) => (
    <Stack direction="column" spacing={2} {...props} />
);

export const FormToolbar: React.FC<StackProps> = (props) => (
    <Stack direction="row" spacing={2} {...props} />
);

export const FormVToolbar: React.FC<StackProps> = (props) => (
    <FormToolbar direction="column" spacing={3} {...props} />
);

type FormLayoutProps = StackProps<'form', { component: 'form' }>;

const FormLayout: React.FC<FormLayoutProps> = (props) => {
    return <Stack direction="column" spacing={5} {...props} />;
};

export default FormLayout;
