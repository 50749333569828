import ComponentsTheme from './ComponentsTheme';

const MuiTabPanel: ComponentsTheme['MuiTabPanel'] = {
    styleOverrides: {
        root: {
            marginTop: 0,
            padding: 0,
        },
    },
};

export default MuiTabPanel;
