export type Slot = {
    from: string;
    to: string;
    state: string;
};

export enum ServiceId {
    LEGAL_OPENING_ACCOUNT = 1001,
}

export type Employee = {
    id: number;
    name: string;
};

export type EmployeeSchedule = Employee & {
    slots: Slot[];
    dayOffed: boolean;
};

export type Schedule = {
    date: string;
    employees: EmployeeSchedule[];
};

type ScheduleData = {
    id: number;
    address: string;
    metro: string;
    city: string;
    schedule: Schedule[];
};

export default ScheduleData;
