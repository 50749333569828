import { Suspense as ReactSuspense, lazy, ReactNode } from 'react';
import { Route, Routes } from 'react-router-dom';
import NoMatch from 'views/NoMatch';
import {
    LoginPageLayout,
    OfferLayout,
    PreorderLayout,
    PublicPageLayout,
    UserPageLayout,
    // PublicPageFullLayout,
} from 'layout';

// offer
const Preorder = lazy(() => import('views/Preorder'));
const Offer = lazy(() => import('views/Offer'));
const GettingStarted = lazy(() => import('views/GettingStarted'));
const InitOffer = lazy(() => import('views/InitOffer'));
const PscbSiteWidget = lazy(() => import('views/PscbSiteWidget'));

// public
const Registration = lazy(() => import('views/Registration'));
const ProxyLogin = lazy(() => import('views/ProxyLogin'));
const Login = lazy(() => import('views/Login'));
const LoginWidget = lazy(() => import('views/LoginWidget'));
const AccessRecovery = lazy(() => import('views/AccessRecovery'));
// const CurrencyTransaction = lazy(() => import('views/CurrencyTransaction'));
const Playground = lazy(() => import('views/Playground'));

// client
const AddNewClient = lazy(() => import('views/AddNewClient'));
const UserClients = lazy(() => import('views/UserClients'));

// user
const UserSettings = lazy(() => import('views/UserSettings'));

const Suspense: React.FC<{ children: ReactNode }> = ({ children }) => (
    <ReactSuspense fallback={null}>{children}</ReactSuspense>
);

const AppRoutes = () => (
    <Routes>
        <Route path="/offer" element={<OfferLayout />}>
            <Route
                path=":accessId"
                element={
                    <Suspense>
                        <Offer />
                    </Suspense>
                }
            />
        </Route>
        <Route path="/preorder" element={<PreorderLayout />}>
            <Route
                index
                element={
                    <Suspense>
                        <Preorder />
                    </Suspense>
                }
            />
        </Route>
        <Route
            path="/account-opening-widget"
            element={
                <Suspense>
                    <PscbSiteWidget />
                </Suspense>
            }
        />
        <Route element={<PublicPageLayout />}>
            <Route
                path="/offer/:accessId/getting-started"
                element={
                    <Suspense>
                        <GettingStarted />
                    </Suspense>
                }
            />
            <Route
                path="/login-widget"
                element={
                    <Suspense>
                        <LoginWidget />
                    </Suspense>
                }
            />
            <Route
                path="/registration"
                element={
                    <Suspense>
                        <Registration />
                    </Suspense>
                }
            />
            <Route
                path="/access-recovery"
                element={
                    <Suspense>
                        <AccessRecovery />
                    </Suspense>
                }
            />
            <Route
                path="/proxy/:token"
                element={
                    <Suspense>
                        <ProxyLogin />
                    </Suspense>
                }
            />
            <Route
                path="/playground/*"
                element={
                    <Suspense>
                        <Playground />
                    </Suspense>
                }
            />
        </Route>
        {/* <Route element={<PublicPageFullLayout />}>
            <Route
                path="/currency-transaction"
                element={
                    <Suspense>
                        <CurrencyTransaction />
                    </Suspense>
                }
            />
        </Route> */}
        <Route element={<LoginPageLayout />}>
            <Route
                path="/login"
                element={
                    <Suspense>
                        <Login />
                    </Suspense>
                }
            />
        </Route>
        <Route path="/" element={<UserPageLayout />}>
            <Route
                path="init-offer/:accessId"
                element={
                    <Suspense>
                        <InitOffer />
                    </Suspense>
                }
            />
            <Route
                path="user-settings"
                element={
                    <Suspense>
                        <UserSettings />
                    </Suspense>
                }
            />
            <Route
                path="add-new-client"
                element={
                    <Suspense>
                        <AddNewClient />
                    </Suspense>
                }
            />
            <Route
                path="clients/:clientId/*"
                element={
                    <Suspense>
                        <UserClients />
                    </Suspense>
                }
            />
        </Route>
        <Route element={<PublicPageLayout />}>
            <Route path="*" element={<NoMatch />} />
        </Route>
    </Routes>
);

export default AppRoutes;
