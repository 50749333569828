import { OfferState } from 'types/Offer';
import { Button, ButtonProps } from '@mui/material';
import { Link } from 'react-router-dom';
import { useOfferQuery } from 'views/common';

const AgreeButton: React.FC<ButtonProps> = (props) => {
    const { data: offer, isSuccess } = useOfferQuery();
    if (!isSuccess) return null;
    return (
        <Button
            color="primary"
            size="large"
            component={Link}
            to={`/offer/${offer?.data.accessId}/getting-started`}
            {...props}
        >
            {offer?.data?.state === OfferState.CONFIRMED
                ? 'Открытие счета'
                : 'Да, открываем счет'}
        </Button>
    );
};

export default AgreeButton;
