import { Container, ContainerProps } from '@mui/material';

const ContentLayout: React.FC<ContainerProps> = ({ sx, ...rest }) => (
    <Container
        component="main"
        maxWidth="lg"
        sx={{
            mt: '100px',
            pt: 6,
            pb: 5,
            flexGrow: 1,
            ...sx,
        }}
        {...rest}
    />
);

export default ContentLayout;
