import { Keys } from 'enums';
import { some } from 'lodash';
import { Client } from 'react-query-client/dto';
import { useParams } from 'react-router-dom';
import { useOverviewQuery } from 'views/common';

const getLastSelectedClientId = (clients: Client[] = []) => {
    const savedClientId =
        localStorage.getItem(Keys.SELECTED_CLIENT_ID_KEY) || undefined;
    const hasSavedClient = some(clients, (client) => {
        return client.id === savedClientId;
    });
    return hasSavedClient ? savedClientId : undefined;
};

const useCurrentClientId = () => {
    const { clientId } = useParams();
    const { data } = useOverviewQuery();

    const primaryClientId = data?.data.primaryClientId;
    const lastSelectedClientId = getLastSelectedClientId(data?.data?.clients);

    return clientId || lastSelectedClientId || primaryClientId;
};

export default useCurrentClientId;
