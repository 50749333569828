import { SxProps } from '@mui/system';

const stickySx: SxProps = {
    '& thead th': {
        position: 'sticky',
        top: '99px',
        zIndex: 1,
    },
};

export default stickySx;
