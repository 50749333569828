import ym from 'react-yandex-metrika';

const callApi = (methodName: string, ...args: any[]) => {
    if (process.env.NODE_ENV === 'production') {
        try {
            ym(methodName, ...args);
        } catch (error) {
            console.error(error);
        }
    }
};

export default callApi;
