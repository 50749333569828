import { ErrorOutline } from '@mui/icons-material';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
    DialogTitle,
    Stack,
} from '@mui/material';
import { isThenable } from 'helpers';
import { useClickableElement, useShow, useShowChange } from 'components/hooks';
import { Fragment, ReactElement, ReactNode, useState } from 'react';
import LoadingButton from './LoadingButton';
import CancelButton from './CancelButton';

export type ModalConfirmProps = Omit<DialogProps, 'open' | 'onClose'> & {
    title?: string;
    confirmMessage?: ReactNode;
    okText?: string;
    cancelText?: string;
    icon?: ReactElement | null;
    children: ReactElement;
    onConfirm?: () => any | PromiseLike<any>;
    onShowChange?: (show: boolean) => void;
};

const ModalConfirm: React.FC<ModalConfirmProps> = ({
    title,
    confirmMessage,
    okText,
    cancelText,
    icon = <ErrorOutline color="warning" />,
    children,
    onConfirm,
    onShowChange,
    ...dialogProps
}) => {
    const [show, onShow, onClose] = useShow();
    const [loading, setLoading] = useState(false);
    const triggerElement = useClickableElement(children, onShow);

    useShowChange(show, onShowChange);

    const handleConfirm = () => {
        const confirmReult = onConfirm?.();
        if (isThenable(confirmReult)) {
            setLoading(true);
            confirmReult.finally(() => {
                setLoading(false);
                onClose();
            });
        } else {
            onClose();
        }
    };

    return (
        <Fragment>
            {triggerElement}
            <Dialog open={show} onClose={onClose} {...dialogProps}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Stack direction="row" spacing={1}>
                        {icon}
                        <DialogContentText>{confirmMessage}</DialogContentText>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        variant="contained"
                        loading={loading}
                        onClick={handleConfirm}
                    >
                        {okText}
                    </LoadingButton>
                    <CancelButton disabled={loading} onClick={onClose}>
                        {cancelText}
                    </CancelButton>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

ModalConfirm.defaultProps = {
    title: 'Подтверждение действия',
    confirmMessage: 'Вы подтверждаете действие?',
    okText: 'Да',
    cancelText: 'Нет',
};

export default ModalConfirm;
