import { MoreVert } from '@mui/icons-material';
import React, { ReactElement } from 'react';
import { useMemo } from 'react';
import {
    MenuWithTrigger,
    MenuWithTriggerProps,
    MenuItemIcon,
    MenuItemIconProps,
} from './menu';
import { IconButton } from '@mui/material';

type RecordMenuItemProps = MenuItemIconProps & {
    onItemClick?: (record: any) => void;
};

export const RecordMenuItem: React.FC<RecordMenuItemProps> = ({
    onItemClick,
    ...rest
}) => {
    return <MenuItemIcon {...rest} />;
};

type RecordMenuProps = MenuWithTriggerProps & {
    record: any;
};

const RecordMenu: React.FC<RecordMenuProps> = ({
    record,
    children,
    ...menuProps
}) => {
    const items = useMemo(() => {
        return React.Children.map(children, (child) => {
            const item = child as ReactElement<RecordMenuItemProps>;
            return React.cloneElement(item, {
                ...item.props,
                onClick: () => item.props.onItemClick?.(record),
            });
        });
    }, [record, children]);

    return (
        <MenuWithTrigger
            id={record.id}
            trigger={
                <IconButton sx={{ padding: 0 }}>
                    <MoreVert />
                </IconButton>
            }
            {...menuProps}
        >
            {items!}
        </MenuWithTrigger>
    );
};

export default RecordMenu;
