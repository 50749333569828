import ComponentsTheme from './ComponentsTheme';

const MuiAlert: ComponentsTheme['MuiAlert'] = {
    styleOverrides: {
        outlinedError: {
            color: '#ff0000',
            backgroundColor: '#fff6f6',
            borderColor: '#ffb3b3',
        },
        outlinedSuccess: {
            color: '#2ead2f',
            backgroundColor: '#f3ffee',
            borderColor: '#5ceb0d',
        },
        outlinedWarning: {
            color: '#8a6d3b',
            backgroundColor: '#fcf8e3',
            borderColor: '#e4d8bd',
        },
    },
    defaultProps: {
        icon: false,
    },
};

export default MuiAlert;
