import { QueryClient } from '@tanstack/react-query';
import axios from 'axios';

const { REACT_APP_API_URL } = process.env;

export const publicApi = axios.create({
    baseURL: REACT_APP_API_URL,
});

export const api = axios.create({
    baseURL: REACT_APP_API_URL,
});

export const setApiToken = (value: string | null) => {
    api.defaults.headers.common.Authorization = `Bearer ${value}`;
};

export const removeApiToken = () => {
    delete api.defaults.headers.common.Authorization;
};

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
            staleTime: Infinity,
        },
    },
});

export default queryClient;
