import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack } from '@mui/material';
import { Mailto } from 'components';
import { PSCB_PLUS_EMAIL } from 'configs/urls';

const MAGIC_MAILTO_BODY_LIMIT = 1600;

export type ErrorSnackbarActionProps = {
    details?: string;
    onClose: () => void;
};

type ReportButtonProps = Omit<ErrorSnackbarActionProps, 'onClose'>;

const ReportButton: React.FC<ReportButtonProps> = ({ details }) => {
    const subject = 'Ошибка при работе ПСКБ +';
    const body = `Произошла ошибка: ${details!.substring(
        0,
        MAGIC_MAILTO_BODY_LIMIT
    )}`;
    return (
        <Mailto
            email={PSCB_PLUS_EMAIL}
            subject={subject}
            body={body}
            underline="always"
            sx={{
                color: 'inherit',
                textDecorationColor: 'inherit',
            }}
        >
            сообщить
        </Mailto>
    );
};

const ErrorSnackbarAction: React.FC<ErrorSnackbarActionProps> = ({
    onClose,
    ...rest
}) => {
    return (
        <Stack direction="row" spacing={1} alignItems="center">
            {rest.details && <ReportButton {...rest} />}
            <IconButton color="inherit" onClick={onClose}>
                <CloseIcon />
            </IconButton>
        </Stack>
    );
};

export default ErrorSnackbarAction;
