import { PaletteOptions } from '@mui/material';

const palette: PaletteOptions = {
    primary: {
        main: '#5ceb0d',
        light: '#d9ffc4',
    },
    secondary: {
        main: '#f50057',
    },
    action: {
        focus: '#00ff00',
    },
    text: {
        primary: '#4d4f53',
        secondary: '#888',
    },
    success: {
        main: '#45d14c',
    },
    error: {
        main: '#ff0000',
    },
};

export default palette;
