import { atom, selector } from 'recoil';
import { isEmpty } from 'helpers';
import { Token } from 'types';
import tokenEffect from './tokenEffect';
import { parseToken } from './utils';

export const tokenState = atom<string | null>({
    key: 'token',
    default: null,
    effects: [tokenEffect],
});

export const tokenDataSelector = selector<Token | null>({
    key: 'tokenData',
    get: ({ get }) => {
        const token = get(tokenState);
        return parseToken(token);
    },
});

export const isTokenExpiredSelector = selector<boolean>({
    key: 'isTokenExpired',
    get: ({ get }) => {
        const tokenData = get(tokenDataSelector);
        return tokenData ? tokenData.exp < Date.now() / 1000 : true;
    },
});

export const loggedInSelector = selector<boolean>({
    key: 'loggedIn',
    get: ({ get }) => {
        const token = get(tokenState);
        return !isEmpty(token) && !get(isTokenExpiredSelector);
    },
});
