import { RouterMenuItem, RouterMenuItemProps } from 'components/menu';
import { useCurrentClientId } from 'hooks';

const ClientMenuItem: React.FC<RouterMenuItemProps> = ({ to, ...props }) => {
    const clientId = useCurrentClientId();
    const fullPath = `clients/${clientId}/${to}`;
    return <RouterMenuItem to={fullPath} {...props} />;
};

export default ClientMenuItem;
