import ComponentsTheme from './ComponentsTheme';
import palette from './palette';

const MuiToggleButton: ComponentsTheme['MuiToggleButton'] = {
    styleOverrides: {
        sizeSmall: {
            padding: '7px 15px',
        },
        root: {
            '&.Mui-selected': {
                color: palette.text?.primary,
            },
        },
    },
};

export default MuiToggleButton;
