const cut = (
    source: string,
    maxLength: number,
    appendDots: boolean = true
): string => {
    return (
        source.slice(0, maxLength) +
        (source.length > maxLength && appendDots ? '...' : '')
    );
};

export default cut;
