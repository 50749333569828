import { TableCell, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { map } from 'lodash';
import { useMemo } from 'react';
import ColumnHeader from './ColumnHeader';
import ColumnSortCell from './ColumnSortCell';
import TableColumn from './TableColumn';
import TableProps from './TableProps';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.grey[50],
}));

const Head = function <TData>({
    columns,
    expandable,
    rowActions,
    ...sortProps
}: TableProps<TData>) {
    const cells = useMemo(() => {
        return map(columns, (column: TableColumn<TData>, index) => {
            return (
                <StyledTableCell
                    key={index}
                    align={column.align}
                    sx={{
                        minWidth: column.minWidth,
                        width: column.maxWidth,
                    }}
                >
                    {column.sorter ? (
                        <ColumnSortCell column={column} {...sortProps} />
                    ) : (
                        <ColumnHeader {...column} />
                    )}
                </StyledTableCell>
            );
        });
    }, [columns, sortProps]);

    return (
        <TableHead>
            <TableRow>
                {expandable && <StyledTableCell sx={{ width: '66px' }} />}
                {cells}
                {rowActions && <StyledTableCell sx={{ width: '73px' }} />}
            </TableRow>
        </TableHead>
    );
};

export default Head;
