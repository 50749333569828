import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { CssBaseline, SnackbarOrigin } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ruRU } from '@mui/x-date-pickers/locales';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import 'dayjs/locale/ru';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'react-query-client';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryFallback, ScrollToTopFab } from 'components';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import theme from 'theme';
import Main from './Main';
import dayjs from 'dayjs';
import Metrika from './Metrika';
import { AbilityProvider } from 'abac';

const emotionCache = createCache({
    key: 'pscb-plus',
    // disable speedy mode for ya webvisor
    speedy: false,
});

dayjs.locale('ru');
dayjs.extend(relativeTime);
dayjs.extend(duration);

const localeText = ruRU.components.MuiLocalizationProvider.defaultProps.localeText;

const anchorOrigin: SnackbarOrigin = {
    vertical: 'top',
    horizontal: 'center',
};

const App = () => (
    <ErrorBoundary fallbackRender={ErrorBoundaryFallback}>
        <CacheProvider value={emotionCache}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <RecoilRoot>
                    <BrowserRouter>
                        <SnackbarProvider
                            anchorOrigin={anchorOrigin}
                            autoHideDuration={3000}
                            hideIconVariant={false}
                            preventDuplicate
                            style={{ whiteSpace: 'pre-line' }}
                        >
                            <QueryClientProvider client={queryClient}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    localeText={localeText}
                                    adapterLocale="ru"
                                >
                                    <AbilityProvider>
                                        <Main />
                                    </AbilityProvider>
                                </LocalizationProvider>
                            </QueryClientProvider>
                        </SnackbarProvider>
                        <Metrika />
                    </BrowserRouter>
                </RecoilRoot>
                <ScrollToTopFab />
            </ThemeProvider>
        </CacheProvider>
    </ErrorBoundary>
);

export default App;
