import { Stack, Typography } from '@mui/material';
import { Href } from 'components';
import { INTERNET_BANKING_DEMO_URL } from 'configs/urls';

const Demo = () => (
    <Stack
        direction="row"
        justifyContent="center"
        sx={{
            backgroundColor: '#dfe0e161',
            padding: '10px 20px',
            borderRadius: '6px',
            fontSize: '1rem',
        }}
    >
        <Typography>
            Демо ДБО Банка <Href href={INTERNET_BANKING_DEMO_URL}>здесь</Href>{' '}
            (логин: <b>f2b-korenkov_vi0909</b>, пароль: <b>Gfhjkm_12</b>)
        </Typography>
    </Stack>
);

export default Demo;
