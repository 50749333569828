import { useShow } from 'components/hooks';
import { Fragment, HtmlHTMLAttributes, ReactNode } from 'react';
import { DrawerProps } from 'components/Drawer';

type DrawerRenderProps = Pick<DrawerProps, 'open'> & {
    onClose: () => void;
};

type ButtonRenderProps = Pick<HtmlHTMLAttributes<any>, 'onClick'>;

type ShowDrawerProps = {
    /**
     * Drawer renderer.
     */
    renderDrawer: (props: DrawerRenderProps) => ReactNode;
    /**
     * Trigger renderer.
     */
    renderTrigger: (props: ButtonRenderProps) => ReactNode;
};

const ShowDrawer: React.FC<ShowDrawerProps> = ({
    renderDrawer,
    renderTrigger,
}) => {
    const [show, onShow, onClose] = useShow();

    const drawer = renderDrawer({ open: show, onClose });
    const button = renderTrigger({ onClick: onShow });

    return (
        <Fragment>
            {drawer}
            {button}
        </Fragment>
    );
};

export default ShowDrawer;
