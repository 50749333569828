import { Link, LinkProps } from '@mui/material';
import { mailtoHref } from 'helpers/format';
import React from 'react';

export const BL = '%0D%0A';

type MailtoBaseProps = {
    /**
     * Email address.
     */
    email?: string;
    /**
     * Email subject.
     */
    subject?: string;
    /**
     * Email text.
     */
    body?: string;
};

export type MailtoProps = LinkProps & MailtoBaseProps;

const Mailto = React.forwardRef<any, MailtoProps>(
    ({ email, subject, body, children, ...rest }, ref) => {
        const href = mailtoHref(email || (children as string), subject, body);
        return (
            <Link ref={ref} href={href} {...rest}>
                {children || email}
            </Link>
        );
    }
);

export default Mailto;
