import { isEmpty, size } from 'lodash';
import { useCurrentClient } from 'hooks';
import Multiple from './Multiple';
import Single from './Single';
import { IconButton, Stack } from '@mui/material';
import { Add } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import { useOverviewQuery } from 'views/common';

const Clients = () => {
    const current = useCurrentClient();
    const { data } = useOverviewQuery();
    const clients = data?.data.clients;
    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            {!isEmpty(clients) ? (
                size(clients) > 1 ? (
                    <Multiple data={clients} selected={current} />
                ) : (
                    <Single data={current} />
                )
            ) : null}
            <IconButton
                size="small"
                component={RouterLink}
                to="/add-new-client"
                title="Добавить организацию"
            >
                <Add />
            </IconButton>
        </Stack>
    );
};

export default Clients;
