import ComponentsTheme from './ComponentsTheme';

const MuiTooltip: ComponentsTheme['MuiTooltip'] = {
    styleOverrides: {
        arrow: {
            color: 'rgba(0, 0, 0, 0.7)',
        },
        tooltip: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            fontSize: '12px',
            padding: '8px 12px',
            fontWeight: 400,
        },
    },
    defaultProps: {
        arrow: true,
    },
};

export default MuiTooltip;
