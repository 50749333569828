import { MongoAbility, subject } from '@casl/ability';

export const hasAvailableActions = (
    ability: MongoAbility,
    subjectType: any,
    subjectInstance: any
): boolean => {
    return ability
        .actionsFor(subjectType)
        .some((action) =>
            ability.can(action, subject(subjectType, subjectInstance))
        );
};

export const hasTruthResult = (...args: boolean[]) => {
    return args.some((result) => result);
};
