import Offer, { OfferState, OfferType, QuestionaryState } from 'types/Offer';
import { DocumentState, DocumentType } from 'types/DocumentPacket';
import { DocumentPacketState } from 'types/DocumentPacket';
import { Branch } from 'enums';

let offerMock: Offer = {
    id: 1712,
    accessId: '0205009621-774337f8aaf1c5ef',
    type: OfferType.ACCOUNT_OPENING_RKO,
    publishedAt: '2023-06-30T16:35:43.93533',
    subjectTaxId: '781446321902', // '7816405237'
    subjectName: 'ООО "ТРИ ЛУНЫ"',
    content: {
        en: '#### Аccount opening\n\n* Account opening - 3000 RUB at any [office](http://www.pscb.ru/locations/offices/) of our bank\n* Business card to account - 1000 rubles issue and first year of service (300,000 rubles withdrawal limit per month)\n\n#### Settlement and cash services in RUB:\n\nTariffs for main operations:\n* Account maintenance fee -  1000 RUB per month\n* External payment order - 35 RUB\n\nSee all tariffs for settlement and cash services on the [Bank\'s website](http://www.pscb.ru/corp/services/raschetnoe-i-kassovoe-obsluzhivanie/).\n\n#### About the Bank\n\nThe Bank [JSC Bank "PSKB"](http://www.pscb.ru) was founded in 1993. National rating from [RA Expert](https://www.raexpert.ru) - ruBBB- (positive)    \n\n\n',
        de: '#### Kontoeröffnung\n\n* Kontoeröffnung - 3000 RUB in jeder [Bankfiliale](http://www.pscb.ru/locations/offices/)\n* Visitenkarte zu Konto - 1000-Rubel-Ausgabe und erstes Dienstjahr (300.000-Rubel-Abhebungslimit pro Monat)\n\n#### Abrechnungs- und Bargelddienstleistungen in Rubel\n\nTarife für rundlegende operationen:\n* Kontoführungsgebühr 1000 RUB pro Monat\n* Externer Zahlungsauftrag 35 RUB\n\nAlle Tarife für Abrechnungs- und Bargelddienste auf der [Bank-Website](http://www.pscb.ru/corp/services/raschetnoe-i-kassovoe-obsluzhivanie/).\n\n#### Über die Bank\n\nDie Bank [JSC Bank PSKB](http://www.pscb.ru) wurde 1993 gegründet. Nationale Bewertung von [Expert RA](https://www.raexpert.ru) - ruBBB- (positiv)\n\n\n',
        ru: '#### Открытие счета\n\n* Открытие счета - 3000 рублей в любом [офисе](http://www.pscb.ru/locations/offices/) банка\n* Бизнес-карта к р/счету - 1000 рублей выпуск и первый год обслуживания(300 000 рублей лимит на снятия в месяц)\n\n#### Расчетно-кассовое обслуживание в рублях\n\nТарифы по основным операциям:\n* Плата за ведение счета 1000 рублей в месяц\n* Внешнее платежное поручение 35 рублей\n\nВсе тарифы расчетно-кассового обслуживания на [сайте банка](http://www.pscb.ru/corp/services/raschetnoe-i-kassovoe-obsluzhivanie/).\n\n#### О Банке\n\nБанк [АО Банк «ПСКБ»](http://www.pscb.ru) образован в 1993 году. Национальный рейтинг от [Эксперт РА](https://www.raexpert.ru) - ruBBB- (развивающийся)    \n\n\n',
        cn: '#### 账户开立\n\n* 账户开立 - 3000 RUB 在任何[银行分行](http://www.pscb.ru/locations/offices/)\n* 名片到 帳戶 - 發行 1000 盧布和第一年服務（每月提款限額 300,000 盧布）\n\n#### 卢布出纳结算业务\n\n主要的业余价格:\n* 账户服务费： 1000 RUB (按月收取)\n* 对外支付令： 35 RUB\n\n[银行网站](http://www.pscb.ru/corp/services/raschetnoe-i-kassovoe-obsluzhivanie/)上有卢布出纳结算业务服务价格表.\n\n#### 关于银行\n\n圣彼得堡社会商业银行[PSCB 股份公司](http://www.pscb.ru) 自1993年起开始从事银行金融业服务. 全俄长期等级 [Эксперт РА](https://www.raexpert.ru) - ruBBB- (积极)\n\n\n',
    },
    state: OfferState.CONFIRMED,
    feedbackEmail: 'MAK@pscb.ru',
    documentPacket: {
        id: 840,
        state: DocumentPacketState.NEW,
        comment: null,
        documents: [
            {
                id: 8324,
                files: [
                    // {
                    //     name: '7d87c516-06ae-4527-9d5e-1c70dd0c60ad.xlsx',
                    //     originalName: 'Отчет по дивидендам от 02.05.2023.xlsx',
                    //     createdAt: '2023-07-04T09:50:17.049',
                    // },
                    // {
                    //     name: '46351ab1-43d4-417c-aea4-93fd209690ae.pdf',
                    //     originalName:
                    //         'Топ исполнителей по КП от 11.05.2023 (1).pdf',
                    //     createdAt: '2023-06-16T10:11:25.027',
                    // },
                ],
                data: {
                    type: DocumentType.CREATE_LEGAL_PROTOCOL,
                },
                state: {
                    value: DocumentState.APPROVED,
                    comment: null,
                },
            },
            {
                id: 8325,
                files: [],
                data: {
                    type: DocumentType.CHARTER,
                },
                state: {
                    value: DocumentState.NEW,
                    comment: null,
                },
            },
            {
                id: 8326,
                files: [],
                data: {
                    type: DocumentType.LEADER_ELECTION_PROTOCOL_AND_POWERS,
                },
                state: {
                    value: DocumentState.NEW,
                    comment: null,
                },
            },
            {
                id: 8327,
                files: [
                    // {
                    //     name: '7d87c516-06ae-4527-9d5e-1c70dd0c60ad.pdf',
                    //     originalName: 'Анкета от 02.05.2023.pdf',
                    //     createdAt: '2023-07-04T09:50:17.049',
                    // },
                ],
                data: {
                    type: DocumentType.PASSPORTS,
                },
                state: {
                    value: DocumentState.NEW,
                    comment: null,
                },
            },
            {
                id: 8328,
                data: {
                    type: DocumentType.ARCHIVE,
                },
                state: {
                    value: DocumentState.NEW,
                    comment: null,
                },
                files: [
                    // {
                    //     name: '7d87c516-06ae-4527-9d5e-1c70dd0c60ad.pdf',
                    //     originalName: 'Анкета от 02.05.2023.pdf',
                    //     createdAt: '2023-07-04T09:50:17.049',
                    // },
                ],
            },
            {
                id: 8329,
                files: [],
                data: {
                    type: DocumentType.RECOMMENDATION,
                },
                state: {
                    value: DocumentState.NEW,
                    comment: null,
                },
            },
            {
                id: 8330,
                files: [
                    // {
                    //     name: '7d87c516-06ae-4527-9d5e-1c70dd0c60ad.pdf',
                    //     originalName: 'Анкета от 02.05.2023.pdf',
                    //     createdAt: '2023-07-04T09:50:17.049',
                    // },
                ],
                data: {
                    type: DocumentType.QUESTIONARY,
                },
                state: {
                    value: DocumentState.NEW,
                    comment: null,
                },
            },
        ],
    },
    options: {
        corpCardPrice: 1000,
        customsCardPrice: 0,
        physicalCardPrice: 500,
        openingPhysicalAccountPrice: 0,
        openingAccountCurrenciesPrice: {
            EUR: 10000,
            USD: 10000,
        },
    },
    products: {
        account: {
            openPrice: 3000,
            dbsAccess: {
                oneTokenFree: false,
                pricePerToken: 3000,
                loginPrice: 1500,
                maintenanceFee: 1000,
            },
            outsideAppointment: false,
        },
    },
    manager: {
        id: 'MANAGER_UUID',
        name: 'Музыка Артем Константинович',
        email: 'MAK@pscb.ru',
        cellPhone: '+79112963198',
        workPhone: '332-26-26',
    },
    bankAccount: '40702810001000900055',
    questionaryState: QuestionaryState.NEW,
    hasClient: false,
    // order: {
    //     total: 4000.0,
    //     services: [
    //         {
    //             name: 'Открытие расчётного счета',
    //             count: 1,
    //             price: 3000.0,
    //             code: 'openingAccount',
    //             subServices: [],
    //         },
    //         {
    //             name: 'Первый месяц обслуживания счёта',
    //             count: 1,
    //             price: 1000.0,
    //             code: 'accountMaintenance',
    //             subServices: [],
    //         },
    //         {
    //             name: 'Открытие счета в валютах',
    //             count: 1,
    //             price: 0.0,
    //             code: 'accountCurrencies',
    //             subServices: [
    //                 {
    //                     name: 'Юань',
    //                     count: 1,
    //                     price: 0.0,
    //                     code: 'CNY',
    //                     subServices: [],
    //                 },
    //             ],
    //         },
    //     ],
    // },
    order: null,
    recommendation: false,
    branch: Branch.SPB,
    outsideAppointRequested: false,
    makeAct: false,
    allowOutsideAppointment: true,
    officePreorder: {
        officeId: null,
        employeeId: null,
    },
};

export default offerMock;
