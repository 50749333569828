import { api } from 'react-query-client';
import { useRecoilCallback } from 'recoil';
import { tokenState } from 'state/loggedIn';
import { AuthResult, Credentials } from 'types';

const useAuth = () => {
    return useRecoilCallback(
        ({ set }) =>
            async (payload: Credentials) => {
                const result = await api.post('/auth', payload);
                const token = (result.data as AuthResult).accessToken;
                set(tokenState, token);
                return result;
            },
        []
    );
};

export default useAuth;
