import {
    // AttachMoney,
    // BarChart,
    // CurrencyExchangeOutlined,
    ExitToApp,
    PersonAdd,
    Settings,
    // ShoppingCart,
} from '@mui/icons-material';
import { Divider } from '@mui/material';
import ClientMenuItem from './ClientMenuItem';
import LogoutMenuItem from './LogoutMenuItem';
import { MenuWithTrigger, RouterMenuItem } from 'components/menu';
import { useCurrentClient } from 'hooks';
import { ClientState } from 'react-query-client/dto/Client';

const MainMenu = () => {
    const currentClient = useCurrentClient();
    const displayClientItems = currentClient?.state === ClientState.ACTIVE;
    return (
        <MenuWithTrigger>
            {displayClientItems && [
                // <ClientMenuItem
                //     icon={<CurrencyExchangeOutlined />}
                //     to="forex"
                //     key="forex"
                // >
                //     Обмен валюты
                // </ClientMenuItem>,
                <ClientMenuItem
                    icon={<PersonAdd />}
                    to="self-employed"
                    key="self-employed"
                >
                    Самозанятые
                </ClientMenuItem>,
                // <ClientMenuItem
                //     icon={<ShoppingCart />}
                //     to="ecommerce"
                //     key="ecommerce"
                // >
                //     E-commerce
                // </ClientMenuItem>,
                // <ClientMenuItem
                //     icon={<BarChart />}
                //     to="analytics"
                //     key="analytics"
                // >
                //     Аналитика
                // </ClientMenuItem>,
                // <ClientMenuItem
                //     icon={<AttachMoney />}
                //     to="accounts"
                //     key="accounts"
                // >
                //     Расчетные счета
                // </ClientMenuItem>,
            ]}
            <RouterMenuItem
                icon={<Settings />}
                to="user-settings"
                key="user-settings"
            >
                Профиль
            </RouterMenuItem>
            <Divider />
            <LogoutMenuItem icon={<ExitToApp />} key="logout">
                Выйти
            </LogoutMenuItem>
        </MenuWithTrigger>
    );
};

export default MainMenu;
