import { useQuery } from '@tanstack/react-query';
import { publicApi } from 'react-query-client';
import { ApiResponse, Preorder } from 'types';
import { AxiosError } from 'axios';
import { isEmpty } from 'helpers';

// MOCK API
// import axios from 'axios';
// import MockAdapter from 'axios-mock-adapter';

// const publicApi = axios.create();
// const mock = new MockAdapter(publicApi, { delayResponse: 300 });

// // @ts-ignore
// mock.onGet(/\/us\/preorders\/(.+)$/).reply(({ url }) => {
//     const taxId = url?.split('/')[3];
//     console.log('on get preorder by taxId=', taxId);
//     return [
//         200,
//         {
//             data: {
//                 phone: '79870085830',
//                 email: 'test@gmail.com',
//             },
//         },
//     ];
// });
//

export type PreorderResponse = ApiResponse<Preorder>;
type PreorderError = AxiosError<PreorderResponse>;

export const fetchPreorder = async (taxId: string) =>
    (await publicApi.get(`/us/preorders/${taxId}`)).data;

const usePreorderQuery = (taxId: string) => {
    return useQuery<PreorderResponse, PreorderError>({
        queryKey: ['preorders', 'taxId', taxId],
        queryFn: () => fetchPreorder(taxId!),
        enabled: !isEmpty(taxId),
    });
};

export default usePreorderQuery;
