import { useEffect } from 'react';
import callApi from './callApi';
import { useRecoilValue } from 'recoil';
import { tokenDataSelector } from 'state/loggedIn';
import { useCurrentClient } from 'hooks';

const useRegChangeClient = () => {
    const tokenData = useRecoilValue(tokenDataSelector);
    const client = useCurrentClient();

    useEffect(() => {
        if (client) {
            callApi('userParams', {
                UserID: tokenData?.userId,
                // userName: tokenData?.username,
                clientName: client?.name,
                taxId: client?.taxId,
            });
        }
    }, [tokenData, client]);
};

export default useRegChangeClient;
