export const mailtoHref = (
    email: string,
    subject?: string,
    body?: string
): string => `
    mailto:${email}
    ${subject ? `?subject=${subject}` : ''}
    ${body ? `&body=${body}` : ''}
`;

export default mailtoHref;
