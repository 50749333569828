const shortFileName = (filename: string, maxLength: number = 30): string => {
    if (filename?.length > maxLength) {
        const offset = Math.floor(maxLength / 2);
        return filename.substr(0, offset) + '...' + filename.substr(-offset);
    } else {
        return filename;
    }
};

export default shortFileName;
