import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, IconButton, Stack } from '@mui/material';
import { useState } from 'react';

type SecretStringProps = {
    children?: string;
};

const SecretString: React.FC<SecretStringProps> = ({ children }) => {
    const [displaying, setDisplaying] = useState(false);
    const Icon = displaying ? Visibility : VisibilityOff;
    return (
        <Stack direction="row" spacing={0.5} alignItems="center">
            <Box
                component="input"
                type={displaying ? 'text' : 'password'}
                value={children}
                border="0px"
                color="currentcolor"
                bgcolor="transparent"
                minWidth="0px"
                size={children?.length}
                disabled
                sx={{
                    font: 'inherit',
                    '&[type="password"]': {
                        letterSpacing: '5px',
                    },
                }}
            />
            <IconButton
                onClick={() => setDisplaying((prev) => !prev)}
                size="small"
            >
                <Icon fontSize="small" />
            </IconButton>
        </Stack>
    );
};

export default SecretString;
