import ComponentsTheme from './ComponentsTheme';

const MuiAppBar: ComponentsTheme['MuiAppBar'] = {
    styleOverrides: {
        root: {
            color: 'inherit',
            backgroundColor: '#ffffff',
        },
    },
    defaultProps: {
        elevation: 0,
    },
};

export default MuiAppBar;
