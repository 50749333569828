import { TableBody } from '@mui/material';
import { LinearLoading } from 'components';
import { isEmpty } from 'helpers';
import { get, has, map } from 'lodash';
import { Fragment, useMemo } from 'react';
import Empty from './Empty';
import FullWidthRow from './FullWidthRow';
import LoadingContainer from './LoadingContainer';
import Row from './Row';
import TableProps from './TableProps';
import getColumnsCount from './getColumnsCount';

const Body = function <TData>(props: TableProps<TData>) {
    const { columns, loading, data, rowKey = 'id', emptyText, bordered, ...rest } = props;
    const showEmpty =
        !loading &&
        (has(data, 'pages')
            ? // @ts-ignore
              isEmpty(data?.pages?.[0].data)
            : isEmpty(data));
    const columnsCount = getColumnsCount(props);

    const rows = useMemo(() => {
        const getRows = (items: TData[]) =>
            map(items, (record: TData, index) => {
                const itemKey = get(record, rowKey, index) as any;
                return (
                    <Row<TData> key={itemKey} columns={columns} record={record} {...rest} />
                );
            });
        const getPages = (pages: any[]) =>
            map(pages, (page, index) => {
                return <Fragment key={index}>{getRows(page.data)}</Fragment>;
            });
        // @ts-ignore
        return has(data, 'pages') ? getPages(data.pages) : getRows(data);
    }, [columns, data, rowKey, rest]);

    return (
        <TableBody
            sx={{
                '& > tr:last-child > td': {
                    borderBottomWidth: 0,
                },
            }}
        >
            <LoadingContainer>
                <LinearLoading loading={loading} />
            </LoadingContainer>
            {rows}
            {showEmpty && (
                <FullWidthRow columnsCount={columnsCount}>
                    <Empty description={emptyText} />
                </FullWidthRow>
            )}
        </TableBody>
    );
};

export default Body;
