import { Href } from 'components';
import { BANK_URL } from 'configs/urls';
import dayjs from 'dayjs';

const currentYear = dayjs().year();

const BankLink = () => (
    <Href href={BANK_URL} fontSize="0.9rem" color="inherit" textAlign="center">
        © 2016-{currentYear} Акционерное общество «Петербургский социальный
        коммерческий банк»
        <br /> Генеральная лицензия ЦБ РФ №2551 от 12 февраля 2015 г.
    </Href>
);

export default BankLink;
