import { Fragment, useContext } from 'react';
import { Field, FieldProps } from 'react-final-form';
import {
    OnBlur,
    OnBlurProps,
    OnChange,
    OnChangeProps,
} from 'react-final-form-listeners';
import FilterContext from './FilterContext';

type ListenerProps = React.FC<OnChangeProps> | React.FC<OnBlurProps>;

export enum FilterTrigger {
    onChange = 'onChange',
    onBlur = 'onBlur',
}

const listeners: { [key in FilterTrigger]: ListenerProps } = {
    [FilterTrigger.onChange]: OnChange,
    [FilterTrigger.onBlur]: OnBlur,
};

type FilterFieldProps = FieldProps<any, any> & {
    filterTrigger: FilterTrigger | null;
};

const FilterField: React.FC<FilterFieldProps> = ({
    filterTrigger,
    ...fieldProps
}) => {
    const submit = useContext(FilterContext);
    const Listener = filterTrigger ? listeners[filterTrigger] : null;
    return (
        <Fragment>
            <Field {...fieldProps} />
            {Listener && (
                <Listener name={fieldProps.name}>
                    {() => {
                        submit?.();
                    }}
                </Listener>
            )}
        </Fragment>
    );
};

export default FilterField;
