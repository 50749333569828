// import SearchWidget from 'views/common/SearchWidget';
import Clients from './Clients';
import HeaderToolbarLayout, {
    HeaderToolbarLayoutContent,
    //HeaderToolbarLayoutSearch,
} from './HeaderToolbarLayout';
import Menu from './Menu';
import Notifications from './Notifications';

const HeaderToolbar = () => (
    <HeaderToolbarLayout>
        {/* <HeaderToolbarLayoutSearch>
            <SearchWidget />
        </HeaderToolbarLayoutSearch> */}
        <HeaderToolbarLayoutContent>
            <Clients />
            <Notifications />
            <Menu />
        </HeaderToolbarLayoutContent>
    </HeaderToolbarLayout>
);

export default HeaderToolbar;
