import { atom, useRecoilValue } from 'recoil';
import Role from './Role';

// TEST
export const roleState = atom<Role>({
    key: 'role',
    default: Role.CLIENT,
});
//

const useRole = (): Role => {
    // TODO: take the role from auth token

    // TEST
    const role = useRecoilValue(roleState);
    return role;
    //
};

export default useRole;
