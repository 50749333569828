import { get as _get } from 'lodash';
import isEmpty from './isEmpty';

// get() function of lodash lib returns a default value
// only if the object[path] value is undefined
// but sometimes you need to take into account such
// values as null, '' and other (see utils/isEmpty)
const get = (object: any, path: string, defaultValue: any = undefined): any => {
    const value = _get(object, path, defaultValue);
    return isEmpty(value) ? defaultValue : value;
};

export default get;
