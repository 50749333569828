import { formatDate } from 'helpers/format';

type DateOutputProps = {
    /**
     * ISO string (like `"2020-09-14"`).
     */
    value?: string;
};

const DateOutput: React.FC<DateOutputProps> = ({ value }) => {
    let formattedDate = '';
    if (value) {
        formattedDate = formatDate(value);
    }
    return <span>{formattedDate}</span>;
};

export default DateOutput;
