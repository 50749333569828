import { Alert, AlertTitle, Box, BoxProps } from '@mui/material';
import { AlertProps, alertClasses } from '@mui/material/Alert';
import React from 'react';
import Mailto from './Mailto';
import { PSCB_PLUS_EMAIL } from 'configs/urls';
import { getErrorMessage, getSupportMessage } from 'helpers/format';

type ErrorAlertProps = AlertProps & {
    title?: string;
    error?: unknown | string;
};

const CodeBox: React.FC<BoxProps> = ({ sx, ...rest }) => (
    <Box
        component="pre"
        width="100%"
        fontSize="12px"
        p="10px"
        mb="0px"
        bgcolor="#fff6f6"
        borderRadius="6px"
        whiteSpace="pre-wrap"
        fontFamily="ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace"
        sx={{
            wordBreak: 'break-word',
            ...sx,
        }}
        {...rest}
    />
);

const ErrorAlert: React.FC<ErrorAlertProps> = ({
    error,
    title,
    sx,
    ...rest
}) => {
    if (error) {
        const errorMessage = getErrorMessage(error);
        const supportMessage = getSupportMessage(errorMessage);
        return (
            <Alert
                severity="error"
                sx={{
                    [`& .${alertClasses.message}`]: {
                        width: '100%',
                    },
                    ...sx,
                }}
                {...rest}
            >
                {title && <AlertTitle sx={{ mb: '10px' }}>{title}</AlertTitle>}
                Пожалуйста, повторите попытку позже или обратитесь{' '}
                <Mailto
                    email={PSCB_PLUS_EMAIL}
                    subject="Ошибка при работе в системе ПСКБ+"
                    body={supportMessage}
                >
                    в службу технической поддержки
                </Mailto>
                {'.'}
                <CodeBox>
                    <>{errorMessage}</>
                </CodeBox>
            </Alert>
        );
    }

    return null;
};

ErrorAlert.defaultProps = {
    title: 'Ой.. произошла какая-то ошибка..',
};

export default ErrorAlert;
