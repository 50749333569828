import { Keys } from 'enums';
import { isEmpty } from 'helpers';
import { AtomEffect, DefaultValue } from 'recoil';
import { tokenTimeout } from './utils';
import { removeApiToken, setApiToken } from 'react-query-client';

const { TOKEN_KEY } = Keys;

const tokenEffect: AtomEffect<string | null> = ({
    setSelf,
    resetSelf,
    onSet,
}) => {
    let timeout: NodeJS.Timeout | undefined;

    const tokenInStorage = localStorage.getItem(TOKEN_KEY);
    const initialValue = !isEmpty(tokenInStorage)
        ? tokenInStorage
        : new DefaultValue();
    setSelf(initialValue);

    if (!isEmpty(tokenInStorage)) {
        setApiToken(tokenInStorage);
    }

    const onStorageChanged = () => {
        const tokenInStorage = localStorage.getItem(TOKEN_KEY);
        if (isEmpty(tokenInStorage)) {
            resetSelf();
        }
    };

    const onTimeout = () => {
        resetSelf();
    };

    onSet((newValue, _, isReset) => {
        if (!isReset) {
            setApiToken(newValue);
            localStorage.setItem(TOKEN_KEY, newValue!);
            window.addEventListener('storage', onStorageChanged);
            timeout = tokenTimeout(newValue, onTimeout);
        } else {
            localStorage.removeItem(TOKEN_KEY);
            window.removeEventListener('storage', onStorageChanged);
            if (timeout) {
                clearTimeout(timeout);
            }
            removeApiToken();
        }
    });
};

export default tokenEffect;
