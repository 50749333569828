import { ReactNode, useMemo } from 'react';
import { AbilityContext } from './context';
import useRole from './useRole';
import { createAbility } from './ability';

type AbilityProviderProps = {
    children: ReactNode;
};

const AbilityProvider: React.FC<AbilityProviderProps> = ({ children }) => {
    const role = useRole();
    const ability = useMemo(() => {
        return createAbility(role);
    }, [role]);
    return (
        <AbilityContext.Provider value={ability}>
            {children}
        </AbilityContext.Provider>
    );
};

export default AbilityProvider;
