import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { isEmpty } from 'helpers';
import { api } from 'react-query-client';
import { ApiResponse } from 'types';
import ScheduleData, { ServiceId } from 'react-query-client/dto/ScheduleData';
import { AxiosError } from 'axios';

// MOCK API
// import axios from 'axios';
// import MockAdapter from 'axios-mock-adapter';
// import scheduleDataMock from './scheduleDataMock';

// const api = axios.create();
// const mock = new MockAdapter(api, { delayResponse: 500 });

// // @ts-ignore
// mock.onGet(/\/cmp\/offices\/(.+)\/schedules\/(.+)$/).reply(({ url }) => {
//     console.log('on get schedule data', url);
//     return [200, scheduleDataMock];
// });
//

export type ScheduleResponse = ApiResponse<ScheduleData>;
export type ScheduleError = AxiosError<ScheduleResponse>;

export const fetchSchedule = (officeId: number) =>
    api
        .get(
            `/cmp/offices/${officeId}/schedules/${ServiceId.LEGAL_OPENING_ACCOUNT}`
        )
        .then((response) => response.data);

const useScheduleQuery = (
    officeId?: number,
    options?: UseQueryOptions<ScheduleResponse, ScheduleError>
) => {
    return useQuery<ScheduleResponse, ScheduleError>(
        ['schedule', { officeId }],
        () => fetchSchedule(officeId!),
        {
            ...options,
            enabled: !isEmpty(officeId),
        }
    );
};

export default useScheduleQuery;
