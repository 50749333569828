import { Typography, TypographyProps } from '@mui/material';
import { formatPhone } from 'helpers/format';

type PhoneProps = Omit<TypographyProps, 'children'> & {
    children?: string;
};

const Phone: React.FC<PhoneProps> = ({ children, ...rest }) => {
    if (!children) return null;
    const formatted = formatPhone(children);
    return (
        <Typography component="span" {...rest}>
            {formatted}
        </Typography>
    );
};

export default Phone;
