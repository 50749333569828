import { Outlet } from 'react-router-dom';
import { Logo } from 'components';
import { DefaultFooter } from 'views/common';
import { HeaderLayout, ContentLayout, FooterLayout, Layout } from './common';

const PublicPageLayout = () => (
    <Layout>
        <HeaderLayout logo={<Logo logo="logo.svg?5" />} />
        <ContentLayout>
            <Outlet />
        </ContentLayout>
        <FooterLayout>
            <DefaultFooter />
        </FooterLayout>
    </Layout>
);

export default PublicPageLayout;
