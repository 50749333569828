import { BreakpointsOptions } from '@mui/material';

const breakpoints: BreakpointsOptions = {
    values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1100,
        xl: 1536,
    },
};

export default breakpoints;
