import { createElement } from 'react';
import Notifications from './Notifications';
import useNotifications from './useNotifications';

const NotificationsContainer = () => {
    const props = useNotifications();
    return createElement(Notifications, props);
};

export default NotificationsContainer;
