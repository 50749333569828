import { useShow } from 'components/hooks';
import React, { Fragment, MouseEvent, ReactElement, ReactNode } from 'react';
import Confirm, { ConfirmProps } from './Confirm';

type ShowConfirmProps = Omit<
    ConfirmProps,
    'show' | 'onClose' | 'onDisagree'
> & { children: ReactNode };

const ShowConfirm: React.FC<ShowConfirmProps> = ({
    children,
    onAgree,
    ...confirmProps
}) => {
    const [show, onShow, onClose] = useShow();

    const handleTriggerClick = (event: MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        onShow();
    };

    const handleAgree = () => {
        onClose();
        onAgree();
    };

    const handleClose = () => {
        onClose();
    };

    const target = children as ReactElement<any>;
    const trigger = React.cloneElement(target, {
        ...target.props,
        onClick: handleTriggerClick,
    });

    return (
        <Fragment>
            <Confirm
                {...confirmProps}
                show={show}
                onDisagree={handleClose}
                onAgree={handleAgree}
                onClose={handleClose}
            />
            {trigger}
        </Fragment>
    );
};

export default ShowConfirm;
