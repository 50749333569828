import { isEmpty } from 'helpers';
import { isValidFileSize } from 'helpers/file';
import { filter } from 'lodash';
import { createElement, useCallback, useMemo, useRef, useState } from 'react';
import { BaseFileSelectorProps } from 'types';

// @deprecated use FileSelector component
const useFileSelector = ({
    multiple,
    accept,
    maxFileSize = 50,
    onSelect,
}: BaseFileSelectorProps) => {
    const inputRef = useRef<HTMLInputElement>();
    const [selectedFiles, setSelectedFiles] = useState<File[] | File>();
    const [invalidFiles, setInvalidFiles] = useState<File[]>();

    const handleChangeFile = useCallback(() => {
        const inputEl = inputRef.current;
        const files = inputEl?.files;
        const invalid = filter(
            files,
            (file) => !isValidFileSize(maxFileSize, file.size)
        );
        if (isEmpty(invalid) && files) {
            setSelectedFiles(multiple ? Array.from(files) : files[0]);
            onSelect?.(files!);
        }
        setInvalidFiles(invalid);
    }, [maxFileSize, multiple, onSelect]);

    const inputFileElement = useMemo(() => {
        return createElement('input', {
            ref: inputRef,
            type: 'file',
            multiple,
            accept,
            style: {
                display: 'none',
            },
            onChange: handleChangeFile,
        });
    }, [inputRef, multiple, accept, handleChangeFile]);

    const onBrowse = useCallback(() => {
        const inputEl = inputRef.current;
        if (inputEl?.value) {
            inputEl.value = '';
        }
        inputEl?.click();
    }, []);

    return {
        inputFileElement,
        selectedFiles,
        invalidFiles,
        onBrowse,
    };
};

export default useFileSelector;
