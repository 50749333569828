import { createElement } from 'react';
import Multiple, { MultipleProps } from './Multiple';
import useMultiple from './useMultiple';

type MultipleContainerProps = Omit<MultipleProps, 'onSelect'>;

const MultipleContainer = (props: MultipleContainerProps) => {
    const hookProps = useMultiple(props);
    return createElement(Multiple, { ...props, ...hookProps });
};

export default MultipleContainer;
