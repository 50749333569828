import { Client } from 'react-query-client/dto';
import { ClientState } from 'react-query-client/dto/Client';

const getClientName = (client?: Client | null) => {
    if (!client) return '';
    if (!client.name || client.state === ClientState.PREORDER) {
        return `ИНН ${client.taxId}`;
    } else {
        return client.name;
    }
};

export default getClientName;
