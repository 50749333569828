import { createElement, HTMLAttributes } from 'react';
import { styled } from '@mui/material/styles';

export type IconProps = {
    /**
     * Icon size (one value for width and height).
     */
    size?: string;
};

export type SvgFileIconProps = HTMLAttributes<HTMLElement> &
    IconProps & {
        /**
         * Contains the path to the image.
         */
        src: string;
    };

const SvgFileIcon = styled(
    ({ src, size = '60px', ...rest }: SvgFileIconProps) =>
        createElement('div', rest)
)(({ size, src }) => ({
    width: size,
    height: size,
    backgroundImage: `url(${src})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
}));

export default SvgFileIcon;
