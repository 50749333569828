import {
    AppBar,
    Container,
    ContainerProps,
    LinearProgress,
    Toolbar,
} from '@mui/material';
import { ReactNode } from 'react';
import noPrint from 'theme/noPrint';

type HeaderLayoutProps = Pick<ContainerProps, 'maxWidth'> & {
    logo?: ReactNode;
    children?: ReactNode;
    loading?: boolean;
};

const HeaderLayout: React.FC<HeaderLayoutProps> = ({
    logo,
    children,
    loading,
    maxWidth = 'lg',
}) => (
    <AppBar
        position="fixed"
        sx={{
            flexShrink: 0,
            ...(!loading
                ? { boxShadow: '0 3px 3px 0 rgba(0, 0, 0, 0.05)' }
                : {}),
            ...noPrint,
        }}
    >
        <Container maxWidth={maxWidth}>
            <Toolbar
                disableGutters
                sx={{
                    justifyContent: 'space-between',
                    gap: {
                        xs: 2,
                        sm: 4,
                    },
                    height: '100px',
                }}
            >
                {logo}
                {children}
            </Toolbar>
        </Container>
        {loading && <LinearProgress color="primary" sx={{ width: '100%' }} />}
    </AppBar>
);

export default HeaderLayout;
