import { useEffect } from 'react';
import theme from 'theme';

const transitionDuration = theme.transitions.duration.standard;

const useShowChange = (
    show: boolean,
    onShowChange?: (show: boolean) => void
) => {
    useEffect(() => {
        if (!onShowChange) return;
        const callback = onShowChange.bind(null, show);
        if (!show) {
            setTimeout(() => {
                callback();
            }, transitionDuration);
        } else {
            callback();
        }
    }, [show, onShowChange]);
};

export default useShowChange;
