import { useCallback, useState } from 'react';

type Result<T> = [
    boolean,
    T | undefined,
    (selectedItem?: T) => void,
    () => void
];

type InitialState<T> = {
    show: boolean;
    selectedItem?: T;
};

const useShowSelectedItem = <T>(defaultSelectedItem?: T): Result<T> => {
    const [state, setState] = useState<InitialState<T>>({
        show: false,
        selectedItem: defaultSelectedItem,
    });

    const onShow = useCallback((selectedItem?: T) => {
        setState({
            show: true,
            selectedItem,
        });
    }, []);

    const onClose = useCallback(() => {
        setState((prevState) => ({
            ...prevState,
            show: false,
        }));
    }, []);

    return [state.show, state.selectedItem, onShow, onClose];
};

export default useShowSelectedItem;
