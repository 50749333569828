import { Stack, Typography } from '@mui/material';
import { Mailto } from 'components';

// const MANAGER_DATA = {
//     name: 'Ляпунов Данила Андреевич',
//     email: 'DALyapunov@pscb.ru',
//     cellPhone: '+7 (960) 252-63-72',
//     workPhone: '8 (800) 200-35-65 (доб.7041)',
//     photoUrl: '/images/admin-photo.png?1',
// };

const MANAGER_DATA = {
    name: 'Макарова Надежда Юрьевна',
    email: 'mnu@pscb.ru',
    cellPhone: '+7 (931) 341-28-21',
    workPhone: '8 (800) 200-35-65 (доб.7351)',
};

const AdminContacts = ({ data = MANAGER_DATA }) => (
    <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="body2">
            {`По вопросам подготовки документов: ${data.name}, `}
            <Mailto email={data.email} />
            {`, ${data.cellPhone}, ${data.workPhone}`}
        </Typography>
    </Stack>
);

export default AdminContacts;
